<template>
  <v-container fluid class="d-flex flex-column">
    <!-- div da pesquisa -->
    <div v-if="strStatusRegistro === ''">
      <div class="pa-2">
        <v-toolbar max-width="500px" class="blue-grey lighten-5">
          <v-text-field
            v-model="edpesquisa"
            hide-details
            single-line
            label="Pesquisa"
          ></v-text-field>
          <v-btn :loading="isloading" icon @click="consultarapi()">
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </v-toolbar>
      </div>
      <div class="pa-2">
        <v-data-table
          v-if="bolMostraGrid"
          :headers="headers"
          :items="dadosConsulta"
          sort-by="nome"
          class="elevation-2"
          disable-sort
          disable-pagination
          hide-default-footer
          dense
          loading-text="Carregando..."
          no-data-text="Nenhum registro encontrado"
        >
          <template v-slot:[`item.acoes`]="{ item }">
            <!-- <v-icon @click="editItem(item)"> mdi-pencil </v-icon> -->
            <v-btn outlined color="primary" dark @click="editItem(item)">
              Editar
            </v-btn>
            <!-- <v-icon @click="deleteItem(item)"> mdi-delete </v-icon> -->
          </template>
        </v-data-table>
      </div>
      <v-footer
        v-if="strStatusRegistro === ''"
        app
        color="primary"
        height="55"
        elevation="20"
      >
        <v-row class="justify-end">
          <v-btn
            v-if="this.$store.state.qPerfil.INCLUIR === 'S'"
            class="ma-2"
            fab
            dark
            small
            color="blue"
            @click="insertItem()"
          >
            <v-icon dark> mdi-plus </v-icon>
          </v-btn>
        </v-row>
      </v-footer>
    </div>
    <!-- div da pesquisa -->
    <!-- div do cadastro -->
    <div v-if="strStatusRegistro !== ''">
      <v-card class="pa-2">
        <v-form ref="form" lazy-validation>
          <v-col>
            <v-text-field
              v-model="jsonPost.RAZAO_PES"
              label="Cliente"
              required
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="jsonPost.TEL1_PES"
              label="Telefone"
              required
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="jsonPost.CELULAR_PES"
              label="Celular"
              required
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="jsonPost.CEP_PES"
              label="CEP"
              required
              append-icon="mdi-magnify"
              :loading="isloadingCEP"
              @click:append="consultaViaCEP()"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-select
              v-model="jsonPost.TIPOEND_PES"
              :items="itemsTipoEnd"
              :rules="[(v) => !!v || 'Item obrigatório']"
              label="Tipo do logradouro"
              required
            ></v-select>
          </v-col>
          <v-col>
            <v-text-field
              v-model="jsonPost.END_PES"
              label="Endereço"
              required
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="jsonPost.NUMERO_PES"
              label="Número"
              required
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="jsonPost.COMPLEMENTO_PES"
              label="Complemento"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="jsonPost.BAIRRO_PES"
              label="Bairro"
              required
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="jsonPost.CIDADE_PES"
              :counter="10"
              label="Cidade"
              required
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="jsonPost.CODIBGE_MUNICIPIO"
              label="Código IBGE"
              required
            ></v-text-field>
          </v-col>
          <v-col>
            <v-select
              v-model="jsonPost.UF_PES"
              :items="itemsUF"
              :rules="[(v) => !!v || 'Item obrigatório']"
              label="UF"
              required
            ></v-select>
          </v-col>
          <v-col>
            <v-textarea
              outlined
              height="400"
              v-model="jsonPost.OBS_PES"
              label="Observações"
            ></v-textarea>
          </v-col>
        </v-form>
      </v-card>
    </div>
    <!-- div do cadastro -->
    <v-footer v-if="strStatusRegistro !== ''" app color="primary">
      <v-btn
        v-if="
          strStatusRegistro === 'INSERT' ||
          (strStatusRegistro === 'EDIT' &&
            this.$store.state.qPerfil.ALTERAR === 'S')
        "
        color="success"
        class="mr-4"
        @click="btnsalvar"
      >
        Salvar
      </v-btn>
      <v-btn color="warning" class="mr-4" @click="btncancelar">
        Cancelar
      </v-btn>
      <v-bottom-sheet
        v-if="
          strStatusRegistro === 'EDIT' &&
          this.$store.state.qPerfil.EXCLUIR === 'S'
        "
        v-model="sheet"
        persistent
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="error" v-bind="attrs" v-on="on"> Excluir </v-btn>
        </template>
        <v-sheet class="text-center" height="200px">
          <div class="py-3">Deseja realmente excluir este registro?</div>
          <v-card class="d-flex justify-space-around mb-6" flat tile>
            <v-btn color="error" @click="deleteItem(jsonPost)"> Sim </v-btn>
            <v-btn color="success" @click="sheet = !sheet"> Não </v-btn>
          </v-card>
        </v-sheet>
      </v-bottom-sheet>
    </v-footer>
    <v-snackbar v-model="snackbar" timeout="4000">
      {{ snackbartext }}
    </v-snackbar>
  </v-container>
</template>

<script>
import api from "../services/api.js";
import viacep from "../services/viacep.js";
import speedio from "../services/speedio.js";

export default {
  name: "Marketing",

  data() {
    return {
      snackbar: false,
      snackbartext: "",
      strStatusRegistro: "",
      isloading: false,
      isloadingCEP: false,
      isloadingCNPJ: false,
      bolMostraGrid: false,
      sqlConsulta: "",
      dadosConsulta: [],
      edpesquisa: "",
      sheet: false,
      headers: [
        {
          text: "Razão Social",
          align: "start",
          value: "RAZAO_PES",
        },
        { text: "Nome Fantasia", value: "NOME_PES" },
        { text: "Endereço", value: "ENDCOMPLETO_PES" },
        { text: "Cidade/UF", value: "CIDADE_PES" },
        { text: "", value: "acoes", sortable: false },
      ],
      itemsFJ: ["FÍSICA", "JURÍDICA"],
      itemsTipoEnd: [
        "RUA",
        "AVENIDA",
        "ESTRADA",
        "TRAVESSA",
        "RODOVIA",
        "PRAÇA",
        "ALAMEDA",
        "GALERIA",
        "CX POSTAL",
        "FAZENDA",
        "PONTE",
      ],
      itemsUF: [
        "AC",
        "AL",
        "AM",
        "AN",
        "AP",
        "BA",
        "CE",
        "DF",
        "ES",
        "EX",
        "GO",
        "MA",
        "MG",
        "MS",
        "MT",
        "PA",
        "PB",
        "PE",
        "PI",
        "PR",
        "RJ",
        "RN",
        "RO",
        "RR",
        "RS",
        "SC",
        "SE",
        "SP",
        "TO",
      ],
      //fields para cadastro
      jsonPost: {
        N_PES: "",
        TIPOPES_PES: "MARKETING",
        RAZAO_PES: "",
        NOME_PES: "",
        TIPOFJ_PES: "",
        CPFCNPJ_PES: "",
        RGIE_PES: "",
        TEL1_PES: "",
        CELULAR_PES: "",
        CEP_PES: "",
        TIPOEND_PES: "",
        END_PES: "",
        NUMERO_PES: "",
        COMPLEMENTO_PES: "",
        BAIRRO_PES: "",
        CIDADE_PES: "",
        CODIBGE_MUNICIPIO: "",
        UF_PES: "",
        N_USU_CAD: "",
        EMAIL_PES: "",
        EMAILNFE_PES: "",
        N_USU_LOG: "",
        OBS_PES: "",
      },
    };
  },
  mounted() {
    this.$store.state.vtitulojanela = "CLIENTE MARKETING";
  },
  methods: {
    consultaSpeedIO() {
      if (this.jsonPost.CPFCNPJ_PES != "") {
        var cnpj = this.jsonPost.CPFCNPJ_PES.replace(/\D/g, "");
        this.isloadingCNPJ = true;
        speedio
          .get(cnpj)
          .then((response) => {
            if (response.data.status === "ERROR") {
              this.isloadingCNPJ = false;
              this.snackbartext = response.data.message;
              this.snackbar = true;
            } else {
              this.jsonPost.TIPOFJ_PES = "JURÍDICA";
              this.jsonPost.RAZAO_PES =
                response.data["RAZAO SOCIAL"].toUpperCase();
              this.jsonPost.NOME_PES =
                response.data["NOME FANTASIA"].toUpperCase();
              this.jsonPost.TEL1_PES = response.data["TELEFONE"].toUpperCase();
              this.jsonPost.NUMERO_PES = response.data["NUMERO"];
              this.jsonPost.CEP_PES = response.data["CEP"];
              this.consultaViaCEP();
              this.isloadingCNPJ = false;
            }
          })
          .catch((error) => {
            this.snackbartext = error.response.message;
            this.snackbar = true;
            this.isloadingCNPJ = false;
          });
      }
    },
    consultaViaCEP() {
      if (this.jsonPost.CEP_PES != "") {
        //Nova variável "cep" somente com dígitos.
        var cep = this.jsonPost.CEP_PES.replace(/\D/g, "");
        //Expressão regular para validar o CEP.
        var validacep = /^[0-9]{8}$/;
        //Valida o formato do CEP.
        if (validacep.test(cep)) {
          this.isloadingCEP = true;
          viacep.get("/" + cep + "/json/").then((response) => {
            if (response.data.erro === true) {
              this.isloadingCEP = false;
              this.snackbartext = "CEP inválido";
              this.snackbar = true;
            } else {
              let strLogradouro = response.data.logradouro.toUpperCase();
              let strTipoLogradouro = strLogradouro.slice(
                0,
                strLogradouro.indexOf(" ")
              );
              let strEndLogradouro = strLogradouro.slice(
                strLogradouro.indexOf(" ") + 1
              );
              this.jsonPost.TIPOEND_PES = strTipoLogradouro;
              this.jsonPost.END_PES = strEndLogradouro;
              this.jsonPost.BAIRRO_PES = response.data.bairro.toUpperCase();
              this.jsonPost.CIDADE_PES = response.data.localidade.toUpperCase();
              this.jsonPost.UF_PES = response.data.uf.toUpperCase();
              this.jsonPost.CODIBGE_MUNICIPIO = response.data.ibge;
              this.isloadingCEP = false;
            }
          });
        } else {
          //cep é inválido.
        }
      }
    },
    consultarapi() {
      if (this.edpesquisa.length >= 3) {
        this.isloading = true;
        this.sqlConsulta =
          "select p.n_pes, p.razao_pes, p.nome_pes, p.tipoend_pes||' '||p.end_pes||', '||p.numero_pes ENDCOMPLETO_PES, " +
          "p.cpfcnpj_pes, p.rgie_pes, p.tel1_pes, p.celular_pes, p.cidade_pes||' / '||p.uf_pes cidade_pes, p.uf_pes " +
          "from adm_pessoa p " +
          "where p.tipopes_pes = 'MARKETING'" +
          "and ( " +
          "(p.razao_pes like '%" +
          this.edpesquisa +
          "%') or (p.nome_pes like '%" +
          this.edpesquisa +
          "%') or (p.cpfcnpj_pes like '%" +
          this.edpesquisa +
          "%') " +
          ") " +
          "order by 2";
        this.sqlConsulta = encodeURIComponent(this.sqlConsulta);
        //console.log(this.sqlConsulta);
        this.dadosConsulta = [];
        api.get("/v1/consulta?sql=" + this.sqlConsulta).then((response) => {
          //console.log(response.data);
          this.dadosConsulta = response.data;
          this.bolMostraGrid = true;
          this.isloading = false;
        });
      }
    },
    insertItem() {
      this.jsonPost.N_PES = "0";
      this.jsonPost.TIPOPES_PES = "MARKETING";
      this.jsonPost.RAZAO_PES = "";
      this.jsonPost.NOME_PES = "";
      this.jsonPost.TIPOFJ_PES = "";
      this.jsonPost.CPFCNPJ_PES = "";
      this.jsonPost.RGIE_PES = "";
      this.jsonPost.TEL1_PES = "";
      this.jsonPost.CELULAR_PES = "";
      this.jsonPost.CEP_PES = "";
      this.jsonPost.TIPOEND_PES = "";
      this.jsonPost.END_PES = "";
      this.jsonPost.NUMERO_PES = "";
      this.jsonPost.COMPLEMENTO_PES = "";
      this.jsonPost.BAIRRO_PES = "";
      this.jsonPost.CIDADE_PES = "";
      this.jsonPost.CODIBGE_MUNICIPIO = "";
      this.jsonPost.UF_PES = "";
      this.jsonPost.N_USU_CAD = this.$store.state.codusuario;
      this.jsonPost.EMAIL_PES = "";
      this.jsonPost.EMAILNFE_PES = "";
      this.jsonPost.N_USU_LOG = this.$store.state.codusuario;
      this.jsonPost.OBS_PES = "";
      this.strStatusRegistro = "INSERT";
      window.scrollTo(0, 0);
    },
    editItem(item) {
      this.isloading = true;
      this.sqlConsulta =
        "select p.n_pes, p.razao_pes, p.nome_pes, p.tipoend_pes, p.end_pes, p.numero_pes, p.email_pes, p.emailnfe_pes, p.cep_pes, p.codibge_municipio, " +
        "p.cpfcnpj_pes, p.rgie_pes, p.tel1_pes, p.celular_pes, p.complemento_pes, p.bairro_pes, p.cidade_pes, p.uf_pes, p.tipofj_pes, p.n_usu_cad, p.obs_pes " +
        "from adm_pessoa p " +
        "where p.n_pes = " +
        item.N_PES;
      this.sqlConsulta = encodeURIComponent(this.sqlConsulta);
      this.jsonPost = [];
      api.get("/v1/consulta?sql=" + this.sqlConsulta).then((response) => {
        this.jsonPost = response.data[0];
        this.jsonPost.N_USU_LOG = this.$store.state.codusuario;
        this.isloading = false;
        this.strStatusRegistro = "EDIT";
        window.scrollTo(0, 0);
      });
    },
    deleteItem(item) {
      //this.jsonPost = [];
      this.jsonPost.N_PES = item.N_PES;
      this.jsonPost.TIPOPES_PES = "MARKETING";
      this.jsonPost.RAZAO_PES = "";
      this.jsonPost.NOME_PES = "";
      this.jsonPost.TIPOFJ_PES = "";
      this.jsonPost.CPFCNPJ_PES = "";
      this.jsonPost.RGIE_PES = "";
      this.jsonPost.TEL1_PES = "";
      this.jsonPost.CELULAR_PES = "";
      this.jsonPost.CEP_PES = "";
      this.jsonPost.TIPOEND_PES = "";
      this.jsonPost.END_PES = "";
      this.jsonPost.NUMERO_PES = "";
      this.jsonPost.CIDADE_PES = "";
      this.jsonPost.CODIBGE_MUNICIPIO = "";
      this.jsonPost.UF_PES = "";
      this.jsonPost.N_USU_CAD = "";
      this.jsonPost.EMAIL_PES = "";
      this.jsonPost.EMAILNFE_PES = "";
      this.jsonPost.OBS_PES = "";
      api
        .delete("/v1/pessoas", {
          data: JSON.stringify(this.jsonPost, this.replacer).toUpperCase(),
        })
        .then(() => {
          //console.log(response);
          this.bolMostraGrid = true;
          this.strStatusRegistro = "";
          this.consultarapi();
          this.sheet = false;
        });
    },
    btnsalvar() {
      //console.log(this.jsonPost);
      api
        .post(
          "/v1/pessoas",
          JSON.stringify(this.jsonPost, this.replacer).toUpperCase()
        )
        .then(() => {
          this.edpesquisa = this.jsonPost.RAZAO_PES;
          window.scrollTo(0, 0);
          this.bolMostraGrid = true;
          this.strStatusRegistro = "";
          this.consultarapi();
        });
    },
    btncancelar() {
      window.scrollTo(0, 0);
      this.strStatusRegistro = "";
    },
    replacer(key, value) {
      // Filtering out properties
      if (value === null) {
        return "";
      }
      return value;
    },
  },
};
</script>