import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store';
import Home from '../views/Home.vue'
import Principal from '../views/Principal.vue'
import Cadastros from '../views/Cadastros.vue'
import Consultas from '../views/Consultas.vue'
import Pessoas from '../views/Pessoas.vue'
import Marketing from '../views/Marketing.vue'
import Contratos from '../views/Contratos.vue'
import Assinatura from '../views/Assinatura.vue'
import Dashboard from '../views/Dashboard.vue'
import Programacao from '../views/Programacao.vue'
import Os from '../views/Os.vue'
import Tracos from '../views/Tracos.vue'
import Corpoprova from '../views/Corpoprova.vue'
import Orcamentos from '../views/Orcamentos.vue'
import Produtosdeposito from '../views/Produtosdeposito.vue'
import Agregados from "../views/Agregados.vue"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },  
  {
    path: '/principal',
    name: 'Principal',    
    component: Principal
  },  
  {
    path: '/cadastros',
    name: 'Cadastros',    
    component: Cadastros
  },  
  {
    path: '/consultas',
    name: 'Consultas',    
    component: Consultas
  },  
  {
    path: '/pessoas',
    name: 'Pessoas',    
    component: Pessoas
  },  
  {
    path: '/marketing',
    name: 'Marketing',    
    component: Marketing
  },  
  {
    path: '/contratos',
    name: 'Contratos',    
    component: Contratos
  },    
  {
    path: '/assinatura',
    name: 'Assinatura',    
    component: Assinatura
  },    
  {
    path: '/dashboard',
    name: 'Dashboard',    
    component: Dashboard
  },    
  {
    path: '/programacao',
    name: 'Programacao',    
    component: Programacao
  },    
  {
    path: '/os',
    name: 'Os',    
    component: Os
  },    
  {
    path: '/tracos',
    name: 'Traços',    
    component: Tracos
  },    
  {
    path: '/corpoprova',
    name: 'Corpoprova',    
    component: Corpoprova
  },    
  {
    path: '/orcamentos',
    name: 'Orcamentos',    
    component: Orcamentos
  },    
  {
    path: '/produtosdeposito',
    name: 'Produtosdeposito',    
    component: Produtosdeposito
  },    
  {
    path: '/agregados',
    name: 'Agregados',    
    component: Agregados
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name !== 'Home' && store.state.codusuario === null) next({ name: 'Home' })
  else next()
})

export default router
