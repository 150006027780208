<template>
  <div>
    <v-tabs v-model="tab" fixed-tabs background-color="primary" dark>
      <v-tabs-slider color="lime lighten-1"></v-tabs-slider>
      <v-tab :disabled="strStatusRegistro !== ''">Consulta</v-tab>
      <v-tab :disabled="strStatusRegistro === ''">Contrato</v-tab>
      <!-- <v-tab :disabled="strStatusRegistro === ''">Produtos</v-tab> -->
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <div class="pa-2">
          <v-toolbar max-width="500px" class="blue-grey lighten-5">
            <v-text-field
              v-model="edpesquisa"
              hide-details
              single-line
              label="Pesquisa"
            ></v-text-field>
            <v-btn :loading="isloading" icon @click="consultarapi()">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </v-toolbar>
        </div>
        <div class="pa-2">
          <v-data-table
            v-if="bolMostraGrid"
            :headers="headers"
            :items="dadosConsulta"
            sort-by="nome"
            class="elevation-2"
            disable-sort
            disable-pagination
            hide-default-footer
            dense
            loading-text="Carregando..."
            no-data-text="Nenhum registro encontrado"
          >
            <template v-slot:[`item.STATUS_CONTRATO`]="{ item }">
              <v-chip :color="getColorStatus(item.STATUS_CONTRATO)" dark>
                {{ item.STATUS_CONTRATO }}
              </v-chip>
            </template>
            <template v-slot:[`item.acoes`]="{ item }">
              <!-- <v-btn
                class="mr-1 my-1"
                outlined
                v-if="item.STATUS_CONTRATO === 'ATIVO'"
                color="primary"
                dark
                small
                :loading="boolLoading"
                @click="perguntaenviaremail(item)"
              >
                Email
              </v-btn> -->
              <v-btn
                class="mr-1 my-1"
                outlined
                v-if="item.STATUS_CONTRATO === 'ATIVO'"
                color="primary"
                dark
                small
                :loading="blLoadingPDF"
                @click="baixarPDF(item)"
              >
                PDF
              </v-btn>
              <v-btn
                class="mr-1 my-1"
                outlined
                :color="item.TEM_ASSINATURA === 0 ? 'error' : 'success'"
                dark
                small
                @click="assinaturadocliente(item)"
              >
                Assinar
              </v-btn>
              <v-btn
                class="mr-1 my-1"
                outlined
                color="primary"
                dark
                small
                @click.stop="chamadialogitens(item)"
              >
                Itens
              </v-btn>
              <v-btn
                class="my-1"
                outlined
                color="primary"
                dark
                small
                @click="editItem(item)"
              >
                Editar
              </v-btn>
              <v-btn
                class="mr-1 my-1"
                outlined
                color="primary"
                dark
                small
                :loading="boolLoading"
                @click.stop="chamadialogentregaos(item)"
              >
                Entrega O.S.
              </v-btn>
            </template>
          </v-data-table>
          <v-dialog
            fullscreen
            transition="dialog-bottom-transition"
            v-model="dialogassinatura"
            max-width="100%"
            width="100%"
          >
            <v-card width="300px">
              <v-toolbar dark color="orange darken-4">
                <v-toolbar-title>Assinatura do cliente</v-toolbar-title>
              </v-toolbar>
            </v-card>
          </v-dialog>
        </div>
      </v-tab-item>
      <v-tab-item v-if="strStatusRegistro !== ''">
        <v-container fluid class="d-flex flex-column">
          <!-- div do cadastro -->
          <div v-if="strStatusRegistro !== ''">
            <v-card class="pa-2">
              <v-form ref="form" lazy-validation>
                <v-col>
                  <v-card class="mx-auto mb-2 pa-2" max-width="344" outlined>
                    <v-card-title
                      >CONTRATO: {{ strNumeroContrato }}</v-card-title
                    >
                    <v-card-title>DATA: {{ strDataContrato }}</v-card-title>
                  </v-card>
                  <v-card class="mx-auto pa-2" max-width="344" outlined>
                    <v-card-title>CLIENTE</v-card-title>
                    <v-list-item three-line>
                      <v-list-item-content>
                        <v-list-item-subtitle>{{
                          strNomeCliente
                        }}</v-list-item-subtitle>
                        <v-list-item-subtitle>{{
                          strCpfCnpjCliente
                        }}</v-list-item-subtitle>
                        <v-list-item-subtitle>{{
                          strEndCliente
                        }}</v-list-item-subtitle>
                        <v-list-item-subtitle>{{
                          strCidadeUFCliente
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-col>
                      <v-row justify="center">
                        <v-btn
                          small
                          color="success"
                          dark
                          @click.stop="dialog = true"
                        >
                          Selecionar cliente
                        </v-btn>

                        <v-dialog
                          fullscreen
                          transition="dialog-bottom-transition"
                          v-model="dialog"
                          max-width="290"
                        >
                          <v-card>
                            <v-toolbar dark color="success">
                              <v-btn icon @click.stop="dialog = false">
                                <v-icon>mdi-arrow-left</v-icon>
                              </v-btn>
                              <v-text-field
                                v-model="edpesquisaCliente"
                                hide-details
                                single-line
                                label="Pesquisa de clientes"
                              ></v-text-field>
                              <v-btn
                                :loading="isloading"
                                icon
                                @click="consultarapiClientes()"
                              >
                                <v-icon>mdi-magnify</v-icon>
                              </v-btn>
                            </v-toolbar>
                            <div class="pa-2">
                              <v-data-table
                                v-if="bolMostraGridCliente"
                                :headers="headerscliente"
                                :items="dadosConsultaCliente"
                                sort-by="nome"
                                class="elevation-2"
                                disable-sort
                                disable-pagination
                                hide-default-footer
                                dense
                                loading-text="Carregando..."
                                no-data-text="Nenhum registro encontrado"
                              >
                                <template v-slot:[`item.acoes`]="{ item }">
                                  <v-btn
                                    color="success"
                                    outlined
                                    @click="selecionaCliente(item)"
                                    >SELECIONAR</v-btn
                                  >
                                </template>
                              </v-data-table>
                            </div>
                          </v-card>
                        </v-dialog>
                      </v-row>
                    </v-col>
                  </v-card>
                </v-col>
                <v-card class="mt-3" dark color="primary">
                  <div class="pa-2 text-center text-h6">Dados do contrato</div>
                </v-card>
                <v-col>
                  <v-select
                    v-model="jsonPost.RETENCAO"
                    :items="itemsSimNao"
                    :rules="[(v) => !!v || 'Item obrigatório']"
                    label="Retenção de ISS"
                    required
                  ></v-select>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="jsonPost.NUMERO_PEDIDO_CLIENTE"
                    label="Pedido do cliente"
                    :maxlength="20"
                    :counter="20"
                    required
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="jsonPost.CODCEI_OBRA"
                    label="Código CNO / CEI da Obra"
                    :maxlength="50"
                    :counter="50"
                    required
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="jsonPost.APLICACAO_CONCRETO"
                    label="Aplicação do produto"
                    :maxlength="60"
                    :counter="60"
                    :rules="[(v) => !!v || 'Item obrigatório']"
                    required
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="jsonPost.VALIDADE_CONTRATO"
                    label="Validade do contrato"
                    type="date"
                    :rules="[(v) => !!v || 'Item obrigatório']"
                    required
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="jsonPost.LIMITE_FATURAMENTO"
                    label="Limite de faturamento"
                    required
                    :rules="[(v) => !!v || 'Item obrigatório']"
                    :disabled="bolAlteraLimite"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="jsonPost.PRAZO_PAGAMENTO"
                    label="Prazo de pagto"
                    :maxlength="30"
                    :counter="30"
                    :rules="[(v) => !!v || 'Item obrigatório']"
                    required
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-select
                    v-model="jsonPost.N_TIP"
                    :items="qTipoCobranca"
                    item-text="DESCRICAO"
                    item-value="N_TIP"
                    :rules="[(v) => !!v || 'Item obrigatório']"
                    label="Forma de pagto"
                    required
                  ></v-select>
                </v-col>
                <v-card dark color="primary">
                  <div class="pa-2 text-center text-h6">Endereço da Obra</div>
                </v-card>
                <v-col>
                  <v-row justify="center">
                    <v-btn
                      @click="btnCopiarEndFiscal()"
                      class="mt-4"
                      small
                      dark
                      color="success"
                      >Copiar do endereço fiscal</v-btn
                    >
                  </v-row>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="jsonPost.IDENTIFICACAO_OBRA"
                    label="Identificação da obra"
                    :maxlength="100"
                    :counter="100"
                    required
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="jsonPost.CEP_OBRA"
                    label="CEP"
                    append-icon="mdi-magnify"
                    :maxlength="9"
                    :counter="9"
                    :loading="isloadingCEP"
                    @click:append="consultaViaCEP()"
                    required
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-select
                    v-model="jsonPost.TIPOEND_OBRA"
                    :items="this.$store.state.itemsTipoEnd"
                    :rules="[(v) => !!v || 'Item obrigatório']"
                    label="Tipo endereço"
                    required
                  ></v-select>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="jsonPost.END_OBRA"
                    label="Endereço"
                    :maxlength="60"
                    :counter="60"
                    :rules="[(v) => !!v || 'Item obrigatório']"
                    required
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="jsonPost.NUMERO_OBRA"
                    label="Número"
                    type="number"
                    required
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="jsonPost.COMPLEMENTO_OBRA"
                    label="Complemento"
                    :maxlength="40"
                    :counter="40"
                    required
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="jsonPost.BAIRRO_OBRA"
                    label="Bairro"
                    :maxlength="50"
                    :counter="50"
                    :rules="[(v) => !!v || 'Item obrigatório']"
                    required
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="jsonPost.CIDADE_OBRA"
                    label="Cidade"
                    :maxlength="50"
                    :counter="50"
                    :rules="[(v) => !!v || 'Item obrigatório']"
                    required
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="jsonPost.CODIBGE_MUNICIPIO"
                    label="Código IBGE"
                    :maxlength="20"
                    :counter="20"
                    required
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-select
                    v-model="jsonPost.UF_OBRA"
                    :items="this.$store.state.itemsUF"
                    :rules="[(v) => !!v || 'Item obrigatório']"
                    label="UF"
                    required
                  ></v-select>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="jsonPost.QUADRA"
                    label="Quadra"
                    :maxlength="10"
                    :counter="10"
                    required
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="jsonPost.LOTE"
                    label="Lote"
                    :maxlength="10"
                    :counter="10"
                    required
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="jsonPost.LATITUDE_CON"
                    label="Latitude"
                    :maxlength="50"
                    :counter="50"
                    required
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="jsonPost.LONGITUDE_CON"
                    label="Longitude"
                    :maxlength="50"
                    :counter="50"
                    required
                  ></v-text-field>
                </v-col>
                <v-card dark color="primary">
                  <div class="pa-2 text-center text-h6">Contatos</div>
                </v-card>
                <v-col>
                  <v-text-field
                    v-model="jsonPost.NOME_ENGENHEIRO"
                    label="Engenheiro"
                    :maxlength="50"
                    :counter="50"
                    required
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="jsonPost.TELEFONE_ENGENHEIRO"
                    label="Telefone engenheiro"
                    :maxlength="14"
                    :counter="14"
                    required
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="jsonPost.NOME_APONTADOR"
                    label="Apontador/Responsável"
                    :maxlength="50"
                    :counter="50"
                    required
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="jsonPost.TELEFONE_APONTADOR"
                    label="Telefone apontador"
                    :maxlength="14"
                    :counter="14"
                    required
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="jsonPost.NOME_MESTRE"
                    label="Mestre de obra"
                    :maxlength="50"
                    :counter="50"
                    required
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="jsonPost.TELEFONE_MESTRE"
                    label="Telefone mestre de obra"
                    :maxlength="14"
                    :counter="14"
                    required
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="jsonPost.OUTROS_CONTATOS"
                    label="Outros contatos"
                    :maxlength="50"
                    :counter="50"
                    required
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="jsonPost.TELEFONE_OUTROS_CONTATOS"
                    label="Telefone"
                    :maxlength="14"
                    :counter="14"
                    required
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="jsonPost.NOME_CONTATO"
                    label="Falar com"
                    :maxlength="50"
                    :counter="50"
                    required
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="jsonPost.TELEFONE_CONTATO"
                    label="Telefone"
                    :maxlength="14"
                    :counter="14"
                    required
                  ></v-text-field>
                </v-col>
              </v-form>
            </v-card>
          </div>
          <!-- div do cadastro -->
        </v-container>
      </v-tab-item>
    </v-tabs-items>
    <v-footer
      v-if="strStatusRegistro === ''"
      app
      color="primary"
      height="55"
      elevation="20"
    >
      <v-row class="justify-end">
        <v-btn
          v-if="this.$store.state.qPerfil.INCLUIR === 'S'"
          class="ma-2"
          fab
          dark
          small
          color="blue"
          @click="novoContrato()"
        >
          <v-icon dark> mdi-plus </v-icon>
        </v-btn>
      </v-row>
    </v-footer>
    <v-footer v-if="strStatusRegistro !== ''" app color="primary">
      <v-btn
        v-if="
          strStatusRegistro === 'INSERT' ||
          (strStatusRegistro === 'EDIT' &&
            this.$store.state.qPerfil.ALTERAR === 'S')
        "
        color="success"
        class="mr-4"
        @click="btnsalvar"
      >
        Salvar
      </v-btn>
      <v-btn color="warning" class="mr-4" @click="btncancelar">
        Cancelar
      </v-btn>
      <v-bottom-sheet
        v-if="
          strStatusRegistro === 'EDIT' &&
          this.$store.state.qPerfil.EXCLUIR === 'S'
        "
        v-model="sheet"
        persistent
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="error" v-bind="attrs" v-on="on"> Excluir </v-btn>
        </template>
        <v-sheet class="text-center" height="200px">
          <div class="py-3">Deseja realmente excluir este registro?</div>
          <v-card class="d-flex justify-space-around mb-6" flat tile>
            <v-btn color="error" @click="deleteItem(jsonPost)"> Sim </v-btn>
            <v-btn color="success" @click="sheet = !sheet"> Não </v-btn>
          </v-card>
        </v-sheet>
      </v-bottom-sheet>
    </v-footer>
    <v-bottom-sheet v-model="sheetOpenOffice" persistent>
      <v-sheet class="text-center" height="200px">
        <div class="py-3">Deseja realmente enviar este contrato por email?</div>
        <v-card class="d-flex justify-space-around mb-6" flat tile>
          <v-btn color="error" @click="enviarporemail()"> Sim </v-btn>
          <v-btn
            color="success"
            @click.stop="sheetOpenOffice = !sheetOpenOffice"
          >
            Não
          </v-btn>
        </v-card>
      </v-sheet>
    </v-bottom-sheet>
    <v-snackbar v-model="snackbar" timeout="4000">
      {{ snackbartext }}
    </v-snackbar>
    <v-dialog
      fullscreen
      transition="dialog-bottom-transition"
      v-model="dialogItens"
      max-width="290"
    >
      <v-card>
        <v-toolbar dark color="pink darken-1">
          <v-btn icon @click.stop="dialogItens = false">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-toolbar-title
            >ITENS DO CONTRATO {{ jsonPost.NUMERO_CONTRATO }}</v-toolbar-title
          >
        </v-toolbar>
        <v-data-table
          :headers="headersproduto"
          :items="dadosConsultaProdutos"
          sort-by="NOME_PRO"
          class="elevation-2"
          disable-sort
          disable-pagination
          hide-default-footer
          dense
          loading-text="Carregando..."
          no-data-text="Nenhum registro encontrado"
        >
          <template v-if="this.$store.state.qPerfil.ALTERAR === 'S'" v-slot:top>
            <v-toolbar class="mt-2" flat>
              <v-spacer></v-spacer>
              <v-btn
                color="pink darken-1"
                dark
                class="mb-2"
                @click.stop="novoItemContrato()"
              >
                Adicionar
              </v-btn>
              <v-dialog v-model="dialogproduto" v-if="dialogproduto" persistent>
                <v-card>
                  <v-card-title>
                    <span class="text-h5"> Item do contrato </span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="6" md="4">
                          <v-select
                            v-model="tfilho.N_PRO"
                            :items="qTracos"
                            item-text="NOME_PRO"
                            item-value="N_PRO"
                            :rules="[(v) => !!v || 'Item obrigatório']"
                            label="Traço"
                            required
                            dense
                          ></v-select>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            v-model.number="tfilho.QTDE"
                            label="Qtde"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            v-model.number="tfilho.VALOR_UNIT"
                            label="Valor unitário"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            v-model.number="tfilho.VALOR_BOMBA"
                            label="Valor bomba"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            v-model.number="tfilho.VALOR_M3_FALT"
                            label="Valor m3 faltante"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            v-model="tfilho.DATA_EMISSAO"
                            label="Data emissão"
                            type="date"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-select
                            v-model="tfilho.STATUS"
                            :items="itemsStatusProduto"
                            :rules="[(v) => !!v || 'Item obrigatório']"
                            label="Status do contrato"
                            required
                          ></v-select>
                        </v-col>
                        <!-- <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            v-model.number="tfilho.INDICE_TRACO_ITEM"
                            label="Índice traço"
                          ></v-text-field>
                        </v-col> -->
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn color="success" @click="btnsalvaritemcontrato()">
                      Salvar
                    </v-btn>
                    <v-btn color="warning" @click="dialogproduto = false">
                      Cancelar
                    </v-btn>
                    <v-btn
                      v-if="strStatusRegistroFilho === 'EDIT'"
                      color="error"
                      @click.stop="sheet = !sheet"
                    >
                      Excluir
                    </v-btn>
                    <v-bottom-sheet
                      v-if="strStatusRegistroFilho === 'EDIT'"
                      v-model="sheet"
                      persistent
                    >
                      <v-sheet class="text-center" height="200px">
                        <div class="py-3">
                          Deseja realmente excluir este registro?
                        </div>
                        <v-card
                          class="d-flex justify-space-around mb-6"
                          flat
                          tile
                        >
                          <v-btn color="error" @click="deleteItemContrato()">
                            Sim
                          </v-btn>
                          <v-btn color="success" @click="sheet = !sheet">
                            Não
                          </v-btn>
                        </v-card>
                      </v-sheet>
                    </v-bottom-sheet>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
          <template
            v-if="this.$store.state.qPerfil.ALTERAR === 'S'"
            v-slot:[`item.acoes`]="{ item }"
          >
            <v-btn outlined color="primary" dark @click="editProduto(item)">
              Editar
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-dialog>
    <v-dialog
      fullscreen
      transition="dialog-bottom-transition"
      v-model="dialogEntregaOS"
      max-width="290"
    >
      <v-card>
        <v-toolbar dark color="green darken-1">
          <v-btn icon @click.stop="dialogEntregaOS = false">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-toolbar-title>ENTREGA DE O.S.</v-toolbar-title>
        </v-toolbar>

        <div class="pa-2 text-center text-h6">
          <v-card max-width="500px" class="blue-grey lighten-5">
            <v-col>
              <v-text-field
                v-model="dtData1"
                hide-details
                single-line
                type="date"
                required
                prefix="De: "
              ></v-text-field>
              <v-text-field
                v-model="dtData2"
                hide-details
                single-line
                type="date"
                required
                prefix="Até: "
              ></v-text-field>
            </v-col>
            <v-col>
              <v-btn
                color="primary"
                :loading="isloading"
                @click="carregaGridEntregaOS(itemContratoSelecionado)"
              >
                Pesquisar
              </v-btn>
            </v-col>
          </v-card>
        </div>

        <v-data-table
          :headers="headersentrega"
          :items="dadosConsultaEntregas"
          sort-by="NOME_PRO"
          class="elevation-2"
          disable-sort
          disable-pagination
          hide-default-footer
          dense
          loading-text="Carregando..."
          no-data-text="Nenhum registro encontrado"
        >
        </v-data-table>
        <div class="pb-2 ma-3">
          <v-card max-width="500px">
            <v-card-title class="text-h5">Totais</v-card-title>
            <v-col>
              <v-row dense>
                <v-col> OS: </v-col>
                <v-col class="text-right">
                  {{ dadosConsultaResumo.QTDE_OS }}
                </v-col>
              </v-row>
              <v-row dense>
                <v-col> Concreto Qtde: </v-col>
                <v-col class="text-right">
                  {{ dadosConsultaResumo.QTDE_CONCRETO }}
                </v-col>
              </v-row>
              <v-row dense>
                <v-col> Concreto Valor: </v-col>
                <v-col class="text-right">
                  {{ dadosConsultaResumo.VALOR_CONCRETO }}
                </v-col>
              </v-row>
              <v-row dense>
                <v-col> Argamassa Qtde: </v-col>
                <v-col class="text-right">
                  {{ dadosConsultaResumo.QTDE_ARGAMASSA }}
                </v-col>
              </v-row>
              <v-row dense>
                <v-col> Argamassa Valor: </v-col>
                <v-col class="text-right">
                  {{ dadosConsultaResumo.VALOR_ARGAMASSA }}
                </v-col>
              </v-row>
              <v-row dense>
                <v-col> Outros Qtde: </v-col>
                <v-col class="text-right">
                  {{ dadosConsultaResumo.QTDE_OUTROS }}
                </v-col>
              </v-row>
              <v-row dense>
                <v-col> Outros Valor: </v-col>
                <v-col class="text-right">
                  {{ dadosConsultaResumo.VALOR_OUTROS }}
                </v-col>
              </v-row>
              <v-row dense>
                <v-col> </v-col>
                <v-col class="text-right"> </v-col>
              </v-row>
              <v-row dense>
                <v-col> Valor total: </v-col>
                <v-col class="text-right">
                  {{ dadosConsultaResumo.VALOR_GERAL }}
                </v-col>
              </v-row>
            </v-col>
          </v-card>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import api from "../services/api.js";
import viacep from "../services/viacep.js";

export default {
  name: "Contratos",

  data() {
    return {
      options: {
        duration: 300,
        offset: 0,
        easing: "easeInOutCubic",
      },
      dtData1: "",
      dtData2: "",
      itemContratoSelecionado: "",
      perfil_alteralimite: false,
      isMobile: false,
      isloadingCEP: false,
      boolLoading: false,
      blLoadingPDF: false,
      snackbar: false,
      snackbartext: "",
      datahoje: "",
      bordasassinatura: { border: "black 1px solid" },
      menudataemissaoproduto: false,
      boolMostraAppBarLocal: false,
      tab: null,
      dialog: false,
      dialogItens: false,
      dialogEntregaOS: false,
      dialogproduto: false,
      dialogassinatura: false,
      strStatusRegistro: "",
      strStatusRegistroFilho: "",
      isloading: false,
      bolMostraGrid: false,
      bolMostraGridCliente: false,
      bolAlteraLimite: false,
      bolFiltraVendedor: false,
      sqlConsulta: "",
      dadosConsulta: [],
      dadosConsultaCliente: [],
      dadosConsultaProdutos: [],
      dadosConsultaEntregas: [],
      dadosConsultaResumo: [],
      qTipoCobranca: [],
      qTracos: [],
      edpesquisa: "",
      edpesquisaCliente: "",
      sheet: false,
      sheetOpenOffice: false,
      itemOpenOffice: [],
      strNumeroContrato: "",
      strDataContrato: "",
      strNomeCliente: "",
      strEndCliente: "",
      strCidadeCliente: "",
      strCidadeUFCliente: "",
      strCpfCnpjCliente: "",
      strCEPCliente: "",
      strTipoEndCliente: "",
      strLogradouroCliente: "",
      strEndNumeroCliente: "",
      strBairroCliente: "",
      strCodIBGECliente: "",
      strUFCliente: "",
      qPerfilItens: [],
      headers: [
        {
          text: "Contrato",
          align: "start",
          value: "NUMERO_CONTRATO",
        },
        { text: "Data", value: "DATA_CONTRATO" },
        { text: "Validade", value: "VALIDADE_CONTRATO" },
        { text: "Razão", value: "RAZAO_PES" },
        { text: "Fantasia", value: "NOME_PES" },
        { text: "CPF/CNPJ", value: "CPFCNPJ_PES" },
        { text: "Obra", value: "ENDCOMPLETO_OBRA" },
        { text: "Cidade/UF", value: "CIDADE_OBRA" },
        { text: "Status", value: "STATUS_CONTRATO" },
        { text: "", value: "acoes", sortable: false },
      ],
      headerscliente: [
        {
          text: "Razão Social",
          align: "start",
          value: "RAZAO_PES",
        },
        { text: "Nome Fantasia", value: "NOME_PES" },
        { text: "CPF/CNPJ", value: "CPFCNPJ_PES" },
        { text: "Endereço", value: "ENDCOMPLETO_PES" },
        { text: "Cidade/UF", value: "CIDADE_PES" },
        { text: "", value: "acoes", sortable: false },
      ],
      headersentrega: [
        {
          text: "Traço",
          align: "start",
          value: "NOME_PRO",
        },
        { text: "Qtde", value: "QTDE_TOTAL" },
        { text: "Valor", value: "VALOR_TOTAL" },
      ],
      headersproduto: [
        {
          text: "Traço",
          align: "start",
          value: "NOME_PRO",
        },
        { text: "Qtde", value: "QTDE" },
        { text: "Valor", value: "VALOR_UNIT" },
        { text: "Bomba", value: "VALOR_BOMBA" },
        { text: "Emissão", value: "DATA_EMISSAO" },
        { text: "Status", value: "STATUS" },
        { text: "", value: "acoes", sortable: false },
      ],
      itemsSimNao: ["SIM", "NÃO"],
      itemsStatusContrato: ["ATIVO", "BLOQUEADO", "INATIVO"],
      itemsStatusProduto: ["ATIVO", "INATIVO"],
      itemsFormaPagto: [
        {
          text: "DINHEIRO",
          value: "1",
        },
        {
          text: "BOLETO",
          value: "2",
        },
      ],
      //fields para cadastro
      jsonPost: {
        N_CON: "",
        N_EMP: "",
        N_USU: "",
        N_PES_CLIENTE: "",
        N_PES_VENDEDOR: "",
        RETENCAO: "",
        NUMERO_PEDIDO_CLIENTE: "",
        APLICACAO_CONCRETO: "",
        VALIDADE_CONTRATO: "",
        LIMITE_FATURAMENTO: "",
        PRAZO_PAGAMENTO: "",
        N_TIP: "",
        CEP_OBRA: "",
        TIPOEND_OBRA: "",
        END_OBRA: "",
        NUMERO_OBRA: "",
        COMPLEMENTO_OBRA: "",
        BAIRRO_OBRA: "",
        CIDADE_OBRA: "",
        CODIBGE_MUNICIPIO: "",
        UF_PES: "",
        QUADRA: "",
        LOTE: "",
        ART_FEITO: "",
        ART_NUMERO: "",
        METRAGEM_OBRA: "",
        M3_RECOLHER_ART: "",
        ISS_ISENCAO: "",
        CUSTO_MATERIAIS: "",
        STATUS_CONTRATO: "",
        NOME_ENGENHEIRO: "",
        TELEFONE_ENGENHEIRO: "",
        NOME_APONTADOR: "",
        TELEFONE_APONTADOR: "",
        NOME_MESTRE: "",
        TELEFONE_MESTRE: "",
        OUTROS_CONTATOS: "",
        TELEFONE_OUTROS_CONTATOS: "",
        NOME_CONTATO: "",
        TELEFONE_CONTATO: "",
        LATITUDE_CON: "",
        LONGITUDE_CON: "",
        CODCEI_OBRA: "",
        N_USU_LOG: "",
        IDENTIFICACAO_OBRA: "",
      },
      tfilho: {
        N_ITE_PRO: "",
        N_PRO: "",
        QTDE: "",
        VALOR_UNIT: "",
        VALOR_BOMBA: "",
        DATA_EMISSAO: "",
        STATUS: "",
        N_USU: "",
        DATA_ALT: "",
        N_CON: "",
        DATA_VENCIMENTO: "",
        HORA_CAD: "",
        VALOR_M3_FALT: "",
        INDICE_TRACO_ITEM: "",
        N_USU_LOG: "",
        N_EMP: "",
      },
    };
  },
  mounted() {
    this.$store.state.vtitulojanela = "CONTRATOS";
    this.carregatipocobranca();
    this.carregaTracos();
    this.carregaperfilitens();
    this.tab = 0;
    this.getNow();

    if (this.$store.state.assinatura_pesquisa !== null) {
      this.edpesquisa = this.$store.state.assinatura_pesquisa;
      this.consultarapi();
      this.$store.state.assinatura_N_CON = null;
      this.$store.state.assinatura_pesquisa = null;
    }

    //integração com chamada do cadastro de clientes
    if (this.$store.state.intN_PES > 0) {
      this.novoContrato();
      this.isloading = true;
      this.sqlConsulta =
        "select p.n_pes, p.razao_pes, p.nome_pes, p.cep_pes, p.tipoend_pes, p.end_pes, p.numero_pes, p.tipoend_pes||' '||p.end_pes||', '||p.numero_pes ENDCOMPLETO_PES, " +
        "p.bairro_pes, p.cpfcnpj_pes, p.rgie_pes, p.tel1_pes, p.celular_pes, p.cidade_pes, p.cidade_pes||' / '||p.uf_pes cidadeuf_pes, p.uf_pes, p.codibge_municipio " +
        "from adm_pessoa p " +
        "where p.n_pes = " +
        this.$store.state.intN_PES;
      this.$store.state.intN_PES = 0;
      this.sqlConsulta = encodeURIComponent(this.sqlConsulta);
      this.dadosConsultaCliente = [];
      api.get("/v1/consulta?sql=" + this.sqlConsulta).then((response) => {
        this.dadosConsultaCliente = response.data;
        this.bolMostraGridCliente = true;
        this.isloading = false;
        this.jsonPost.N_PES_CLIENTE = this.dadosConsultaCliente[0].N_PES;
        this.strNomeCliente = this.dadosConsultaCliente[0].RAZAO_PES;
        this.strEndCliente = this.dadosConsultaCliente[0].ENDCOMPLETO_PES;
        this.strCidadeUFCliente = this.dadosConsultaCliente[0].CIDADEUF_PES;
        this.strCidadeCliente = this.dadosConsultaCliente[0].CIDADE_PES;
        this.strCpfCnpjCliente = this.dadosConsultaCliente[0].CPFCNPJ_PES;
        this.strCEPCliente = this.dadosConsultaCliente[0].CEP_PES;
        this.strTipoEndCliente = this.dadosConsultaCliente[0].TIPOEND_PES;
        this.strLogradouroCliente = this.dadosConsultaCliente[0].END_PES;
        this.strEndNumeroCliente = this.dadosConsultaCliente[0].NUMERO_PES;
        this.strBairroCliente = this.dadosConsultaCliente[0].BAIRRO_PES;
        this.strCodIBGECliente = this.dadosConsultaCliente[0].CODIBGE_MUNICIPIO;
        this.strUFCliente = this.dadosConsultaCliente[0].UF_PES;
      });
    }
  },
  methods: {
    getColorStatus(status) {
      if (status === "ATIVO") return "green";
      else return "red";
    },
    consultaViaCEP() {
      if (this.jsonPost.CEP_OBRA != "") {
        //Nova variável "cep" somente com dígitos.
        var cep = this.jsonPost.CEP_OBRA.replace(/\D/g, "");
        //Expressão regular para validar o CEP.
        var validacep = /^[0-9]{8}$/;
        //Valida o formato do CEP.
        if (validacep.test(cep)) {
          this.isloadingCEP = true;
          viacep.get("/" + cep + "/json/").then((response) => {
            if (response.data.erro === true) {
              this.isloadingCEP = false;
              this.snackbartext = "CEP inválido";
              this.snackbar = true;
            } else {
              let strLogradouro = response.data.logradouro.toUpperCase();
              let strTipoLogradouro = strLogradouro.slice(
                0,
                strLogradouro.indexOf(" ")
              );
              let strEndLogradouro = strLogradouro.slice(
                strLogradouro.indexOf(" ") + 1
              );
              this.jsonPost.TIPOEND_OBRA = strTipoLogradouro;
              this.jsonPost.END_OBRA = strEndLogradouro;
              this.jsonPost.BAIRRO_OBRA = response.data.bairro.toUpperCase();
              this.jsonPost.CIDADE_OBRA =
                response.data.localidade.toUpperCase();
              this.jsonPost.UF_OBRA = response.data.uf.toUpperCase();
              this.jsonPost.CODIBGE_MUNICIPIO = response.data.ibge;
              this.isloadingCEP = false;
            }
          });
        } else {
          this.isloadingCEP = false;
          this.snackbartext = "CEP inválido";
          this.snackbar = true;
        }
      }
    },
    btnCopiarEndFiscal() {
      if (this.jsonPost.N_PES_CLIENTE === "") {
        this.snackbartext = "Por favor selecione um cliente";
        this.snackbar = true;
      } else {
        this.jsonPost.CEP_OBRA = this.strCEPCliente;
        this.jsonPost.TIPOEND_OBRA = this.strTipoEndCliente;
        this.jsonPost.END_OBRA = this.strLogradouroCliente;
        this.jsonPost.NUMERO_OBRA = this.strEndNumeroCliente;
        this.jsonPost.BAIRRO_OBRA = this.strBairroCliente;
        this.jsonPost.CIDADE_OBRA = this.strCidadeCliente;
        this.jsonPost.CODIBGE_MUNICIPIO = this.strCodIBGECliente;
        this.jsonPost.UF_OBRA = this.strUFCliente;
      }
    },
    chamadialogitens(item) {
      this.jsonPost = item;
      this.dialogItens = true;
      this.carregaGridTracos(item);
    },
    chamadialogentregaos(item) {
      this.jsonPost = item;
      this.dialogEntregaOS = true;
      this.itemContratoSelecionado = item;
      this.carregaGridEntregaOS(item);
    },
    getNow: function () {
      const today = new Date().toISOString().substr(0, 10);
      this.datahoje = today;
    },
    perguntaenviaremail(item) {
      this.itemOpenOffice = item;
      this.sheetOpenOffice = true;
    },
    enviarporemail() {
      this.sheetOpenOffice = false;
      this.boolLoading = true;
      api
        .get(
          "/v1/contratoporemail?contrato=" +
            this.itemOpenOffice.N_CON +
            "&empresa=" +
            this.$store.state.vempresa +
            "&perfil=" +
            this.$store.state.intN_PER
        )
        .then((response) => {
          this.boolLoading = false;
          this.snackbartext = response.data;
          this.snackbar = true;
        });
    },
    baixarPDF(item) {
      var url =
        "http://" +
        this.$store.state.baseUSER +
        ":" +
        this.$store.state.basePASS +
        "@" +
        this.$store.state.baseIP +
        ":" +
        this.$store.state.basePORT +
        "/v1/contratopdf?contrato=" +
        item.N_CON +
        "&empresa=" +
        this.$store.state.vempresa +
        "&perfil=" +
        this.$store.state.intN_PER;
      window.open(url);
    },
    assinaturadocliente(item) {
      if (item.TEM_ASSINATURA === 0) {
        this.$store.state.assinatura_N_CON = item.N_CON;
        this.$store.state.assinatura_pesquisa = this.edpesquisa;
        this.$router.push({ path: "/assinatura" });
      } else {
        this.snackbartext = "Este contrato já foi assinado";
        this.snackbar = true;
      }
    },
    formatDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      return `${year}-${month}-${day}`;
    },
    formatDateFirebirdConsulta(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    carregatipocobranca() {
      let sql1 =
        "select t.n_tip, t.descricao " +
        "from tipo_cobranca t " +
        "where t.tipo = 'C' " +
        "order by t.descricao";
      sql1 = encodeURIComponent(sql1);
      this.qTipoCobranca = [];
      api.get("/v1/consulta?sql=" + sql1).then((response) => {
        this.qTipoCobranca = response.data;
      });
    },
    limpacamposcliente() {
      this.strNomeCliente = "";
      this.strCpfCnpjCliente = "";
      this.strEndCliente = "";
      this.strCidadeCliente = "";
    },
    carregaperfilitens() {
      api
        .get(
          "/v1/perfildeacessoitem?perfil=" +
            this.$store.state.intN_PER +
            "&tela=93"
        )
        .then((response) => {
          this.qPerfilItens = response.data;

          const valoritem1 = this.qPerfilItens.find(
            (x) => x.DESCRICAO_ITEM === "ALTERA LIMITE"
          ).VALOR_ITEM;
          if (valoritem1 === "N") {
            this.bolAlteraLimite = true; //ao contrário pois campo usa propriedade disabled
          } else {
            this.bolAlteraLimite = false;
          }

          const valoritem2 = this.qPerfilItens.find(
            (x) => x.DESCRICAO_ITEM === "FILTRA CONTRATOS DO VENDEDOR LOGADO"
          ).VALOR_ITEM;
          if (valoritem2 === "N") {
            this.bolFiltraVendedor = false;
          } else {
            this.bolFiltraVendedor = true;
          }
        });
    },
    carregaTracos() {
      let sql2 =
        "select p.n_pro, p.nome_pro " +
        "from produtos P ,produtos_marcas M " +
        "where p.n_pro = m.n_pro " +
        "and p.tipo_pro = 'VENDA' " +
        "and p.tipo_material in ('CONCRETO','ARGAMASSA','OUTROS') " +
        "and p.n_emp = " +
        this.$store.state.vempresa +
        " " +
        "and p.status_pro = 'ATIVO' " +
        "and m.status_marca = 'ATIVO' " +
        "order by p.nome_pro";
      sql2 = encodeURIComponent(sql2);
      this.qTracos = [];
      api.get("/v1/consulta?sql=" + sql2).then((response) => {
        this.qTracos = response.data;
        //console.log(this.qTracos);
      });
    },
    carregaGridTracos(item) {
      this.sqlConsulta =
        "select p.nome_pro, i.qtde, cast(i.valor_unit as float) valor_unit , cast(i.valor_bomba as float) valor_bomba, i.data_emissao, i.hora_cad, " +
        "i.n_ite_pro, i.status, cast(i.valor_m3_falt as float) valor_m3_falt, p.n_emp, i.indice_traco_item, i.data_alt , u.nome_completo " +
        "from produtos p, itens_contrato i, produtos_marcas m , usuario u " +
        "where p.n_pro = i.n_pro " +
        "and  i.n_con = " +
        item.N_CON + // this.jsonPost.N_CON +
        " " +
        "and p.n_emp = " +
        this.$store.state.vempresa +
        " " +
        "and p.n_pro = m.n_pro " +
        "and i.n_usu = u.n_usu " +
        "order by i.n_ite_pro, i.data_emissao";
      this.sqlConsulta = encodeURIComponent(this.sqlConsulta);
      this.dadosConsultaProdutos = [];
      api.get("/v1/consulta?sql=" + this.sqlConsulta).then((response) => {
        this.dadosConsultaProdutos = response.data;
      });
    },
    carregaGridEntregaOS(item) {
      this.sqlConsulta =
        "select 1 ORDEM, p.n_pro, p.nome_pro, sum(o.qtde) qtde_total, cast(sum(o.valor_total) as float) valor_total " +
        "from contratos c, ordem_servico o, produtos p " +
        "where c.n_con = o.n_con " +
        "and o.n_pro = p.n_pro " +
        "and o.status = 'NORMAL' " +
        "and c.n_con = " +
        item.N_CON +
        " ";
      if (this.dtData1 != "" && this.dtData2 != "") {
        this.sqlConsulta =
          this.sqlConsulta +
          "and o.data_os between '" +
          this.formatDateFirebirdConsulta(this.dtData1) +
          "' and '" +
          this.formatDateFirebirdConsulta(this.dtData2) +
          "' ";
      }
      this.sqlConsulta = this.sqlConsulta + "group by 1,2,3 order by 1,3";

      this.sqlConsulta = encodeURIComponent(this.sqlConsulta);
      this.dadosConsultaEntregas = [];
      api.get("/v1/consulta?sql=" + this.sqlConsulta).then((response) => {
        this.dadosConsultaEntregas = response.data;
      });

      this.sqlConsulta =
        "select " +
        "count(o.n_ord) QTDE_OS, " +
        "sum(iif(p.tipo_material = 'CONCRETO',o.qtde,0)) QTDE_CONCRETO, " +
        "sum(iif(p.tipo_material = 'ARGAMASSA',o.qtde,0)) QTDE_ARGAMASSA, " +
        "sum(iif(p.tipo_material = 'OUTROS',o.qtde,0)) QTDE_OUTROS, " +
        "cast(sum(iif(p.tipo_material = 'CONCRETO',o.valor_total,0)) as float) VALOR_CONCRETO, " +
        "cast(sum(iif(p.tipo_material = 'ARGAMASSA',o.valor_total,0)) as float) VALOR_ARGAMASSA, " +
        "cast(sum(iif(p.tipo_material = 'OUTROS',o.valor_total,0)) as float) VALOR_OUTROS, " +
        "cast(sum(o.valor_total) as float) VALOR_GERAL " +
        "from contratos c, ordem_servico o, produtos p " +
        "where c.n_con = " +
        item.N_CON +
        " " +
        "and c.n_con = o.n_con " +
        "and o.n_pro = p.n_pro " +
        "and o.status = 'NORMAL' ";

      if (this.dtData1 != "" && this.dtData2 != "") {
        this.sqlConsulta =
          this.sqlConsulta +
          "and o.data_os between '" +
          this.formatDateFirebirdConsulta(this.dtData1) +
          "' and '" +
          this.formatDateFirebirdConsulta(this.dtData2) +
          "' ";
      }

      this.sqlConsulta = encodeURIComponent(this.sqlConsulta);
      this.dadosConsultaResumo = [];
      api.get("/v1/consulta?sql=" + this.sqlConsulta).then((response) => {
        this.dadosConsultaResumo = response.data;
        this.dadosConsultaResumo = this.dadosConsultaResumo[0];
        //console.log(this.dadosConsultaResumo[0]);
      });
    },
    consultarapiClientes() {
      if (this.edpesquisaCliente.length >= 3) {
        this.isloading = true;
        this.sqlConsulta =
          "select p.n_pes, p.razao_pes, p.nome_pes, p.cep_pes, p.tipoend_pes, p.end_pes, p.numero_pes, p.tipoend_pes||' '||p.end_pes||', '||p.numero_pes ENDCOMPLETO_PES, " +
          "p.bairro_pes, p.cpfcnpj_pes, p.rgie_pes, p.tel1_pes, p.celular_pes, p.cidade_pes, p.cidade_pes||' / '||p.uf_pes cidadeuf_pes, p.uf_pes, p.codibge_municipio " +
          "from adm_pessoa p " +
          "where p.tipopes_pes = 'CLIENTE'" +
          "and p.razao_pes like '%" +
          this.edpesquisaCliente +
          "%' " +
          "order by 2";
        this.sqlConsulta = encodeURIComponent(this.sqlConsulta);
        this.dadosConsultaCliente = [];
        api.get("/v1/consulta?sql=" + this.sqlConsulta).then((response) => {
          this.dadosConsultaCliente = response.data;
          this.bolMostraGridCliente = true;
          this.isloading = false;
        });
      }
    },
    consultarapi() {
      if (this.edpesquisa.length >= 3) {
        this.isloading = true;
        this.sqlConsulta =
          "select c.numero_contrato, c.end_obra, c.numero_obra, c.cidade_obra||'/'||c.uf_obra cidade_obra, c.uf_obra, c.validade_contrato, c.n_pes_cliente, u.nome_completo, " +
          "c.data_contrato, p.razao_pes, p.nome_pes, p.cpfcnpj_pes, c.n_con, c.limite_faturamento, c.prazo_pagamento, c.endcompleto_obra, c.data_cad, c.hora_cad, c.status_contrato, " +
          "iif(c.ASSINATURACLIB64_CON is null,0,1) TEM_ASSINATURA " +
          "from adm_pessoa p, contratos c " +
          "left outer join usuario u on u.n_usu = c.n_usu " +
          "where p.n_pes = c.n_pes_cliente " +
          "and ((p.razao_pes like '%" +
          this.edpesquisa +
          "%') or (p.nome_pes like '%" +
          this.edpesquisa +
          "%') or (c.numero_contrato like '%" +
          this.edpesquisa +
          "%') or (p.cpfcnpj_pes like '%" +
          this.edpesquisa +
          "%')) ";

        if (this.bolFiltraVendedor) {
          this.sqlConsulta =
            this.sqlConsulta +
            "and c.n_pes_vendedor = " +
            this.$store.state.codfuncionario +
            " ";
        }

        this.sqlConsulta =
          this.sqlConsulta + "order by c.data_contrato desc, c.n_con desc";
        this.sqlConsulta = encodeURIComponent(this.sqlConsulta);
        this.dadosConsulta = [];
        api.get("/v1/consulta?sql=" + this.sqlConsulta).then((response) => {
          this.dadosConsulta = response.data;
          this.bolMostraGrid = true;
          this.isloading = false;
        });
      }
    },
    novoContrato() {
      this.jsonPost.N_CON = "0";
      this.jsonPost.N_EMP = this.$store.state.vempresa;
      this.jsonPost.N_USU = this.$store.state.codusuario;
      this.jsonPost.N_PES_CLIENTE = "";
      this.jsonPost.N_PES_VENDEDOR = this.$store.state.codfuncionario;
      this.jsonPost.RETENCAO = "NÃO";
      this.jsonPost.NUMERO_PEDIDO_CLIENTE = "";
      this.jsonPost.APLICACAO_CONCRETO = "";
      this.jsonPost.VALIDADE_CONTRATO = "";
      this.jsonPost.LIMITE_FATURAMENTO = "0,01";
      this.jsonPost.PRAZO_PAGAMENTO = "";
      this.jsonPost.N_TIP = "";
      this.jsonPost.CEP_OBRA = "";
      this.jsonPost.TIPOEND_OBRA = "";
      this.jsonPost.END_OBRA = "";
      this.jsonPost.NUMERO_OBRA = "";
      this.jsonPost.COMPLEMENTO_OBRA = "";
      this.jsonPost.BAIRRO_OBRA = "";
      this.jsonPost.CIDADE_OBRA = "";
      this.jsonPost.CODIBGE_MUNICIPIO = "";
      this.jsonPost.UF_OBRA = "PR";
      this.jsonPost.QUADRA = "";
      this.jsonPost.LOTE = "";
      this.jsonPost.ART_FEITO = "NÃO";
      this.jsonPost.ART_NUMERO = "";
      this.jsonPost.METRAGEM_OBRA = "";
      this.jsonPost.M3_RECOLHER_ART = "";
      this.jsonPost.ISS_ISENCAO = "NÃO";
      this.jsonPost.CUSTO_MATERIAIS = "SIM";
      this.jsonPost.STATUS_CONTRATO = "BLOQUEADO";
      this.jsonPost.NOME_ENGENHEIRO = "";
      this.jsonPost.TELEFONE_ENGENHEIRO = "";
      this.jsonPost.NOME_APONTADOR = "";
      this.jsonPost.TELEFONE_APONTADOR = "";
      this.jsonPost.NOME_MESTRE = "";
      this.jsonPost.TELEFONE_MESTRE = "";
      this.jsonPost.OUTROS_CONTATOS = "";
      this.jsonPost.TELEFONE_OUTROS_CONTATOS = "";
      this.jsonPost.NOME_CONTATO = "";
      this.jsonPost.TELEFONE_CONTATO = "";
      this.jsonPost.LATITUDE_CON = "";
      this.jsonPost.LONGITUDE_CON = "";
      this.jsonPost.CODCEI_OBRA = "";
      this.jsonPost.N_USU_LOG = "";
      this.jsonPost.IDENTIFICACAO_OBRA = "";
      this.strStatusRegistro = "INSERT";
      this.dadosConsultaCliente = [];
      this.dadosConsultaProdutos = [];
      this.edpesquisaCliente = "";
      this.strNumeroContrato = "";
      this.strDataContrato = "";
      window.scrollTo(0, 0);
      this.tab = 1;
    },
    novoItemContrato() {
      this.tfilho.N_ITE_PRO = "0";
      this.tfilho.N_PRO = "";
      this.tfilho.QTDE = "";
      this.tfilho.VALOR_UNIT = "";
      this.tfilho.VALOR_BOMBA = "0,00";
      this.tfilho.DATA_EMISSAO = this.datahoje;
      this.tfilho.STATUS = "ATIVO";
      this.tfilho.N_USU = "";
      this.tfilho.DATA_ALT = "";
      this.tfilho.N_CON = "";
      this.tfilho.DATA_VENCIMENTO = "";
      this.tfilho.HORA_CAD = "";
      this.tfilho.VALOR_M3_FALT = "0,00";
      this.tfilho.INDICE_TRACO_ITEM = "1";
      this.tfilho.N_USU_LOG = this.$store.state.codusuario;
      this.tfilho.N_EMP = this.$store.state.vempresa;

      this.strStatusRegistroFilho = "INSERT";
      this.dialogproduto = true;
    },
    editItem(item) {
      this.isloading = true;
      this.sqlConsulta =
        "select c.*, p.razao_pes, p.cpfcnpj_pes, p.tipoend_pes||' '||p.end_pes||', '||p.numero_pes ENDCOMPLETO_PES, " +
        "p.cidade_pes||' / '||p.uf_pes cidadeuf_pes, 1 N_USU_LOG, " +
        "p.cep_pes, p.tipoend_pes, p.end_pes, p.numero_pes, p.complemento_pes, p.bairro_pes, p.cidade_pes, p.uf_pes, p.codibge_municipio codibge_municipio_pes " +
        "from contratos c, adm_pessoa p " +
        "where c.n_pes_cliente = p.n_pes " +
        "and c.n_con = " +
        item.N_CON;
      this.sqlConsulta = encodeURIComponent(this.sqlConsulta);
      this.jsonPost = [];
      api.get("/v1/consulta?sql=" + this.sqlConsulta).then((response) => {
        this.jsonPost = response.data[0];
        this.strDataContrato = response.data[0].DATA_CONTRATO;
        this.jsonPost.DATA_CONTRATO = this.formatDate(
          this.jsonPost.DATA_CONTRATO
        );
        this.jsonPost.VALIDADE_CONTRATO = this.formatDate(
          this.jsonPost.VALIDADE_CONTRATO
        );
        this.strNumeroContrato = response.data[0].NUMERO_CONTRATO;
        this.strNomeCliente = response.data[0].RAZAO_PES;
        this.strEndCliente = response.data[0].ENDCOMPLETO_PES;
        this.strCidadeCliente = response.data[0].CIDADEUF_PES;
        this.strCidadeUFCliente = response.data[0].CIDADEUF_PES;
        this.strCpfCnpjCliente = response.data[0].CPFCNPJ_PES;

        this.strCEPCliente = response.data[0].CEP_PES;
        this.strTipoEndCliente = response.data[0].TIPOEND_PES;
        this.strLogradouroCliente = response.data[0].END_PES;
        this.strEndNumeroCliente = response.data[0].NUMERO_PES;
        this.strBairroCliente = response.data[0].BAIRRO_PES;
        this.strCidadeCliente = response.data[0].CIDADE_PES;
        this.strCodIBGECliente = response.data[0].CODIBGE_MUNICIPIO_PES;
        this.strUFCliente = response.data[0].UF_PES;

        this.isloading = false;
        this.edpesquisaCliente = "";
        this.strStatusRegistro = "EDIT";
        this.tab = 1;
        //this.carregaGridTracos();
        window.scrollTo(0, 0);
      });
    },
    editProduto(item) {
      this.isloading = true;
      this.sqlConsulta =
        "select c.*, 1 N_USU_LOG " +
        "from itens_contrato c " +
        "where c.n_ite_pro = " +
        item.N_ITE_PRO;
      this.sqlConsulta = encodeURIComponent(this.sqlConsulta);
      this.tfilho = [];
      api.get("/v1/consulta?sql=" + this.sqlConsulta).then((response) => {
        this.tfilho = response.data[0];
        this.tfilho.DATA_EMISSAO = this.formatDate(this.tfilho.DATA_EMISSAO);
        this.isloading = false;
        this.strStatusRegistroFilho = "EDIT";
        this.dialogproduto = true;
      });
    },
    selecionaCliente(item) {
      this.jsonPost.N_PES_CLIENTE = item.N_PES;
      this.strNomeCliente = item.RAZAO_PES;
      this.strEndCliente = item.ENDCOMPLETO_PES;
      this.strCidadeUFCliente = item.CIDADEUF_PES;
      this.strCidadeCliente = item.CIDADE_PES;
      this.strCpfCnpjCliente = item.CPFCNPJ_PES;
      this.strCEPCliente = item.CEP_PES;
      this.strTipoEndCliente = item.TIPOEND_PES;
      this.strLogradouroCliente = item.END_PES;
      this.strEndNumeroCliente = item.NUMERO_PES;
      this.strBairroCliente = item.BAIRRO_PES;
      this.strCodIBGECliente = item.CODIBGE_MUNICIPIO;
      this.strUFCliente = item.UF_PES;
      this.dialog = false;
    },
    deleteItem(item) {
      this.jsonPost.N_CON = item.N_CON;
      api
        .delete("/v1/contratos", {
          data: '{"N_CON":' + item.N_CON + "}",
        })
        .then((response) => {
          if (response.data.RESULTADO === "OK") {
            this.bolMostraGrid = true;
            this.strStatusRegistro = "";
            this.tab = 0;
            this.limpacamposcliente();
            this.consultarapi();
            this.sheet = false;
          } else {
            this.snackbartext = response.data.ERRO;
            this.snackbar = true;
          }
        })
        .catch((error) => {
          this.snackbartext = error.response.data;
          this.snackbar = true;
        });
    },
    btnsalvar() {
      if (this.$refs.form.validate()) {
        if (this.jsonPost.N_PES_CLIENTE === "") {
          this.snackbartext = "Por favor selecione um cliente";
          this.snackbar = true;
        } else {
          this.jsonPost.HISTORICO_STATUS = "";
          this.jsonPost.N_USU_LOG = this.$store.state.codusuario;
          api
            .post(
              "/v1/contratos",
              JSON.stringify(this.jsonPost, this.replacer).toUpperCase()
            )
            .then((response) => {
              if (response.data.RESULTADO === "OK") {
                window.scrollTo(0, 0);
                this.bolMostraGrid = true;
                this.strStatusRegistro = "";
                this.tab = 0;
                this.limpacamposcliente();
                this.edpesquisa = response.data.NUMERO_CONTRATO;
                this.consultarapi();
              } else {
                this.snackbartext = response.data;
                this.snackbar = true;
              }
            })
            .catch((error) => {
              console.log("catch de fora");
              this.snackbartext = error.response.data;
              this.snackbar = true;
            });
        }
      }
    },
    btncancelar() {
      window.scrollTo(0, 0);
      this.strStatusRegistro = "";
      this.tab = 0;
      this.limpacamposcliente();
    },
    replacer(key, value) {
      // Filtering out properties
      if (value === null) {
        return "";
      }
      return value;
    },
    btnsalvaritemcontrato() {
      if (this.tfilho.N_CON === "") {
        this.tfilho.N_CON = this.jsonPost.N_CON;
      }
      if (this.tfilho.N_USU === "") {
        this.tfilho.N_USU = this.$store.state.codusuario;
      }

      this.tfilho.N_EMP = this.$store.state.vempresa;
      this.tfilho.N_USU_LOG = this.$store.state.codusuario;

      api
        .post(
          "/v1/contratositens",
          JSON.stringify(this.tfilho, this.replacer).toUpperCase()
        )
        .then((response) => {
          if (response.data.RESULTADO === "OK") {
            this.dialogproduto = false;
            this.strStatusRegistroFilho = "";
            this.carregaGridTracos(this.tfilho);
          } else {
            this.snackbartext = response.data;
            this.snackbar = true;
          }
        })
        .catch((error) => {
          this.snackbartext = error.response.data;
          this.snackbar = true;
        });
    },
    deleteItemContrato() {
      api
        .delete("/v1/contratositens", {
          data: JSON.stringify(this.tfilho, this.replacer).toUpperCase(),
        })
        .then((response) => {
          if (response.data.RESULTADO === "OK") {
            this.sheet = false;
            this.dialogproduto = false;
            this.strStatusRegistroFilho = "";
            this.carregaGridTracos(this.tfilho);
          } else {
            this.snackbartext = response.data;
            this.snackbar = true;
          }
        })
        .catch((error) => {
          this.snackbartext = error.response.data;
          this.snackbar = true;
        });
    },
  },
};
</script>