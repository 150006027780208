import axios from "axios";

const api = axios.create({               
    baseURL: 'http://127.0.0.1:8082',
    auth: {
        username: 'xsoftware',
        password: '@cafe2007*'
    },
    
});

export default api;