import axios from "axios";

const xnoip = axios.create({   
    baseURL: 'https://xsoftware.dev.br/cgi-bin/sh.cgi',
    auth: {
        username: 'xsoftware',
        password: '@cafe2007*'
    },    
});

export default xnoip;