<template>
  <v-container fluid class="d-flex flex-column">
    <!-- div da pesquisa -->

    <div class="pa-2">
      <v-toolbar max-width="500px" class="blue-grey lighten-5">
        <v-text-field
          v-model="edpesquisa"
          hide-details
          single-line
          label="Pesquisa"
        ></v-text-field>
        <v-btn :loading="isloading" icon @click="consultarapi()">
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </v-toolbar>
    </div>
    <div class="pa-2">
      <v-data-table
        v-if="bolMostraGrid"
        :headers="headers"
        :items="dadosConsulta"
        sort-by="nome"
        class="elevation-2"
        disable-sort
        disable-pagination
        hide-default-footer
        dense
        loading-text="Carregando..."
        no-data-text="Nenhum registro encontrado"
      >
      </v-data-table>
    </div>
    <!-- div da pesquisa -->
    <v-snackbar v-model="snackbar" timeout="4000">
      {{ snackbartext }}
    </v-snackbar>
  </v-container>
</template>

<script>
import api from "../services/api.js";

export default {
  name: "Tracos",

  data() {
    return {
      snackbar: false,
      snackbartext: "",
      isloading: false,
      bolMostraGrid: false,
      sqlConsulta: "",
      dadosConsulta: [],
      edpesquisa: "",
      headers: [
        {
          text: "Produto",
          align: "start",
          value: "NOME_PRO",
        },
        { text: "Un", value: "UN_PRO" },
        { text: "Custo MP", value: "CUSTO_MP" },
        { text: "Valor venda mínimo", value: "VALOR_VENDA" },
      ],
    };
  },
  mounted() {
    this.$store.state.vtitulojanela = "TRAÇOS";
  },
  methods: {
    consultarapi() {
      this.isloading = true;
      this.sqlConsulta =
        "select p.n_pro, p.nome_pro, p.un_pro, cast(a.valor_venda as float) valor_venda, " +
        "coalesce( " +
        " (select cast(sum(d.qtde * " +
        "  (select first(1) c.custo " +
        "  from custos_mp_mensal c " +
        "  where c.n_pro = t.n_pro " +
        "  and c.n_emp = " +
        this.$store.state.vempresa +
        " " +
        "  order by c.ano desc, c.mes desc, c.n_cus desc)) as float) " +
        " from dosagem d, produtos t " +
        " where d.n_pro_principal = p.n_pro " +
        " and d.n_pro_componente = t.n_pro " +
        "),0) CUSTO_MP " +
        "from produtos p, produtos_marcas a " +
        "where p.n_pro = a.n_pro " +
        "and p.n_emp = " +
        this.$store.state.vempresa +
        " " +
        "and p.tipo_material in ('CONCRETO','ARGAMASSA','OUTROS') " +
        "and p.status_pro = 'ATIVO' " +
        "and p.nome_pro <> '' " +
        "and p.nome_pro like '%" +
        this.edpesquisa +
        "%' " +
        "order by p.nome_pro ";
      this.sqlConsulta = encodeURIComponent(this.sqlConsulta);
      //console.log(this.sqlConsulta);
      this.dadosConsulta = [];
      api.get("/v1/consulta?sql=" + this.sqlConsulta).then((response) => {
        //console.log(response.data);
        this.dadosConsulta = response.data;
        this.bolMostraGrid = true;
        this.isloading = false;
      });
    },
    replacer(key, value) {
      // Filtering out properties
      if (value === null) {
        return "";
      }
      return value;
    },
  },
};
</script>