<template>
  <v-container fluid fill-height class="grey lighten-3">
    <v-row align="center">
      <v-col>
        <div class="my-10 text-center">
          <v-btn
            @click="acessarProgramacao()"
            color="primary"
            dark
            x-large
            width="80%"
            >Programação de entrega</v-btn
          >
        </div>
        <div class="my-10 text-center">
          <v-btn @click="acessarOs()" color="primary" dark x-large width="80%"
            >Ordens de serviço</v-btn
          >
        </div>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" timeout="4000">
      {{ snackbartext }}
    </v-snackbar>
  </v-container>
</template>

<script>
import api from "../services/api.js";
import router from "./../router";

export default {
  name: "Cadastros",
  data() {
    return {
      snackbartext: "",
      snackbar: false,
    };
  },
  mounted() {
    this.$store.state.vtitulojanela = this.$store.state.vempresanome;
  },
  methods: {
    acessarProgramacao() {
      api
        .get(
          "/v1/perfildeacesso?perfil=" +
            this.$store.state.intN_PER +
            "&tela=190"
        )
        .then((response) => {
          if (response.data.ACESSAR === "S") {
            this.$store.state.qPerfil = response.data;
            router.push({ path: "/programacao" });
          } else {
            this.snackbartext = "Perfil de acesso: Negado";
            this.snackbar = true;
          }
        });
    },
    acessarOs() {
      api
        .get(
          "/v1/perfildeacesso?perfil=" + this.$store.state.intN_PER + "&tela=94"
        )
        .then((response) => {
          if (response.data.ACESSAR === "S") {
            this.$store.state.qPerfil = response.data;
            router.push({ path: "/os" });
          } else {
            this.snackbartext = "Perfil de acesso: Negado";
            this.snackbar = true;
          }
        });
    },
  },
};
</script>