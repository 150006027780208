import axios from "axios";

const viacep = axios.create({           
    baseURL: 'https://viacep.com.br/ws',    
});

export default viacep;

// https://viacep.com.br/ws/86041286/json/

// {
//     "cep": "86041-286",
//     "logradouro": "Rua José Negri",
//     "complemento": "",
//     "bairro": "Jardim Neman Sahyun",
//     "localidade": "Londrina",
//     "uf": "PR",
//     "ibge": "4113700",
//     "gia": "",
//     "ddd": "43",
//     "siafi": "7667"
// }