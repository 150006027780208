<template>
  <v-container fluid fill-height>
    <VueSignaturePad
      width="100%"
      :height="window.width / 2 + 'px'"
      ref="signaturePad"
      :customStyle="estilo"
    />
    <div class="d-flex justify-center">
      <v-btn color="success" class="mr-2" @click="salvarassinatura"
        >Salvar</v-btn
      >
      <v-btn color="warning" @click="cancelarassinatura">Cancelar</v-btn>
    </div>
  </v-container>
</template>

<script>
import api from "../services/api.js";

export default {
  name: "Assinatura",
  data() {
    return {
      tpai: { N_CON: "", ASSINATURACLIB64_CON: "" },
      estilo: { border: "black 1px solid" },
      window: {
        width: 0,
        height: 0,
      },
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    cancelarassinatura() {
      this.$refs.signaturePad.clearSignature();
      this.$router.back();
    },
    salvarassinatura() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      if (!isEmpty) {
        this.tpai.N_CON = this.$store.state.assinatura_N_CON;
        this.tpai.ASSINATURACLIB64_CON = data;
        this.gravarassinatura();
      }
    },
    gravarassinatura() {
      //console.log(JSON.stringify(this.tpai));
      api.post("/v1/assinaturacliente", JSON.stringify(this.tpai)).then(() => {
        this.$refs.signaturePad.clearSignature();
        this.$router.back();
      });
    },
  },
};
</script>