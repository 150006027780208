<template>
  <v-container fluid class="d-flex flex-column">
    <!-- DIV da pesquisa -->
    <div v-if="strStatusRegistro === ''">
      <div class="pa-2">
        <v-toolbar max-width="500px" class="blue-grey lighten-5">
          <v-text-field
            v-model="edPesquisa"
            hide-details
            single-line
            label="Cliente/Endereço/Cidade"
          ></v-text-field>
          <v-btn :loading="isLoading" icon @click="btnPesquisarClick">
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </v-toolbar>
      </div>
      <div class="pa-2">
        <v-data-table
          v-if="bolMostraGrid"
          :headers="headers"
          :items="qGrid"
          sort-by="nome"
          class="elevation-2"
          disable-sort
          disable-pagination
          hide-default-footer
          dense
          loading-text="Carregando..."
          no-data-text="Nenhum registro encontrado"
        >
          <template v-slot:[`item.botoes`]="{ item }">
            <v-btn
              class="mr-1 my-1"
              outlined
              color="primary"
              dark
              :loading="bolPDF"
              @click="btnPDFClick(item)"
            >
              PDF
            </v-btn>
            <v-btn
              class="mr-1 my-1"
              outlined
              color="primary"
              dark
              @click="btnAlterarClick(item)"
            >
              Editar
            </v-btn>
            <!-- <v-icon @click="deleteItem(item)"> mdi-delete </v-icon> -->
          </template>
        </v-data-table>
      </div>
    </div>
    <!-- DIV dos campos do cadastro -->
    <div v-if="strStatusRegistro !== ''">
      <v-card class="pa-2">
        <v-form ref="form" lazy-validation>
          <v-col>
            <v-text-field
              v-if="strStatusRegistro === 'EDIT'"
              v-model="tpai.NUMERO_ORC"
              label="Orçamento"
              required
              disabled
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-if="strStatusRegistro === 'EDIT'"
              v-model="tpai.DATA_ORC"
              label="Data"
              type="date"
              required
              disabled
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="tpai.NOME_ORC"
              label="Cliente"
              :rules="[(v) => !!v || 'Item obrigatório']"
              required
              append-outer-icon="mdi-magnify"
              @click:append-outer="btnPesquisarClienteClick"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="tpai.CPFCNPJ_ORC"
              label="CPF/CNPJ"
              required
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="tpai.CONTATO_ORC"
              label="Contato"
              required
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="tpai.TEL_ORC"
              label="Telefone"
              :rules="[(v) => !!v || 'Item obrigatório']"
              required
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="tpai.CEL_ORC"
              label="Celular"
              required
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="tpai.EMAIL_ORC"
              label="Email"
              required
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="tpai.APLICACAO_CONCRETO"
              label="Aplicação do concreto"
              required
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="tpai.PRAZO_ORC"
              label="Prazo de pagto"
              :rules="[(v) => !!v || 'Item obrigatório']"
              required
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="tpai.CONDICAO_ORC"
              label="Condição de pagto"
              :rules="[(v) => !!v || 'Item obrigatório']"
              required
            ></v-text-field>
          </v-col>
          <v-col>
            <v-select
              v-model="tpai.N_TIP"
              :items="qTipoCobranca"
              item-text="DESCRICAO"
              item-value="N_TIP"
              :rules="[(v) => !!v || 'Item obrigatório']"
              label="Forma de pagto"
              required
            ></v-select>
          </v-col>
          <v-col>
            <v-text-field
              v-model="tpai.PRAZOEXEC_ORC"
              label="Prazo estimado de execução"
              required
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="tpai.INICIOPREV_ORC"
              label="Início previsto"
              type="Date"
              required
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="tpai.VIGENCIA_ORC"
              label="Vigência da proposta"
              required
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="tpai.CEP_OBRA"
              label="CEP"
              required
              append-outer-icon="mdi-magnify"
              @click:append-outer="btnConsultaViaCEPClick"
              :loading="isLoadingCEP"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-select
              v-model="tpai.TIPOEND_OBRA"
              :items="this.$store.state.itemsTipoEnd"
              :rules="[(v) => !!v || 'Item obrigatório']"
              label="Tipo de endereço"
              required
            ></v-select>
          </v-col>
          <v-col>
            <v-text-field
              v-model="tpai.END_OBRA"
              label="Logradouro"
              :rules="[(v) => !!v || 'Item obrigatório']"
              required
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="tpai.NUMERO_OBRA"
              label="Número"
              required
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="tpai.COMPLEMENTO_OBRA"
              label="Complemento"
              required
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="tpai.BAIRRO_OBRA"
              label="Bairro"
              :rules="[(v) => !!v || 'Item obrigatório']"
              required
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="tpai.CIDADE_OBRA"
              label="Cidade"
              :rules="[(v) => !!v || 'Item obrigatório']"
              required
            ></v-text-field>
          </v-col>
          <v-col>
            <v-select
              v-model="tpai.UF_OBRA"
              :items="this.$store.state.itemsUF"
              :rules="[(v) => !!v || 'Item obrigatório']"
              label="UF"
              required
            ></v-select>
          </v-col>
          <v-col>
            <v-textarea
              v-model="tpai.OBSERVACAO"
              outlined
              label="Observações"
              height="300px"
            ></v-textarea>
          </v-col>
          <v-col>
            <v-row class="ma-1" justify="center">
              <v-btn color="blue-grey" dark @click="btnMostrarItensClick">
                <v-icon dark> mdi-list-box-outline </v-icon>
                Itens do orçamento
              </v-btn>
            </v-row>
          </v-col>
        </v-form>
      </v-card>
    </div>
    <!-- Footer da pesquisa -->
    <v-footer
      v-if="strStatusRegistro === ''"
      app
      color="primary"
      height="55"
      elevation="20"
    >
      <v-row class="justify-end">
        <v-btn
          v-if="this.$store.state.qPerfil.INCLUIR === 'S'"
          class="ma-2"
          fab
          dark
          small
          color="blue"
          @click="btnIncluirClick"
        >
          <v-icon dark> mdi-plus </v-icon>
        </v-btn>
      </v-row>
    </v-footer>
    <!-- Footer da edição -->
    <v-footer v-if="strStatusRegistro !== ''" app color="primary">
      <v-btn
        v-if="
          strStatusRegistro === 'INSERT' ||
          (strStatusRegistro === 'EDIT' &&
            this.$store.state.qPerfil.ALTERAR === 'S')
        "
        color="success"
        class="mr-4"
        @click="btnSalvarClick"
      >
        Salvar
      </v-btn>
      <v-btn color="warning" class="mr-4" @click="btnCancelarClick">
        Cancelar
      </v-btn>
      <v-bottom-sheet
        v-if="
          strStatusRegistro === 'EDIT' &&
          this.$store.state.qPerfil.EXCLUIR === 'S'
        "
        v-model="bolPerguntaExcluir"
        persistent
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="error" v-bind="attrs" v-on="on"> Excluir </v-btn>
        </template>
        <v-sheet class="text-center" height="200px">
          <div class="py-3">Deseja realmente excluir este registro?</div>
          <v-card class="d-flex justify-space-around mb-6" flat tile>
            <v-btn color="error" @click="btnExcluirClick"> Sim </v-btn>
            <v-btn
              color="success"
              @click="bolPerguntaExcluir = !bolPerguntaExcluir"
            >
              Não
            </v-btn>
          </v-card>
        </v-sheet>
      </v-bottom-sheet>
    </v-footer>
    <!-- Dialog da pesquisa de clientes -->
    <v-dialog
      fullscreen
      transition="dialog-bottom-transition"
      v-model="bolDialogPesqCliente"
      max-width="290"
    >
      <v-card>
        <v-toolbar dark color="success">
          <v-btn icon @click.stop="bolDialogPesqCliente = false">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-text-field
            v-model="edPesquisaCliente"
            hide-details
            single-line
            label="Pesquisa de clientes"
          ></v-text-field>
          <v-btn :loading="isLoading" icon @click="btnConsultarClienteClick">
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </v-toolbar>
        <div class="pa-2">
          <v-data-table
            v-if="bolMostraGridCliente"
            :headers="headerscliente"
            :items="qGridClientes"
            sort-by="nome"
            class="elevation-2"
            disable-sort
            disable-pagination
            hide-default-footer
            dense
            loading-text="Carregando..."
            no-data-text="Nenhum registro encontrado"
          >
            <template v-slot:[`item.acoes`]="{ item }">
              <v-btn
                color="success"
                outlined
                @click="btnSelecionarClienteClick(item)"
                >SELECIONAR</v-btn
              >
            </template>
          </v-data-table>
        </div>
      </v-card>
    </v-dialog>
    <!-- Fim do dialog da pesquisa de clientes -->
    <!-- Dialog dos itens -->
    <v-dialog
      fullscreen
      transition="dialog-bottom-transition"
      v-model="bolDialogItens"
      max-width="290"
    >
      <v-card>
        <v-toolbar dark color="blue-grey">
          <v-btn icon @click.stop="bolDialogItens = false">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-toolbar-title>ITENS DO ORÇAMENTO</v-toolbar-title>
        </v-toolbar>
        <div>
          <v-col>
            <v-row class="ma-1" justify="center">
              <v-btn color="blue-grey" dark @click="btnAdicionarProdutoClick">
                Adicionar produto
              </v-btn>
            </v-row>
          </v-col>
        </div>
        <div class="pa-2">
          <v-data-table
            v-if="bolMostraGridItens"
            :headers="headersitens"
            :items="tfilho"
            sort-by="nome"
            class="elevation-2"
            disable-sort
            disable-pagination
            hide-default-footer
            dense
            loading-text="Carregando..."
            no-data-text="Nenhum registro encontrado"
          >
            <template v-slot:[`item.acoes`]="{ index }">
              <v-btn
                class="ma-1"
                color="error"
                outlined
                @click="btnExcluirProdutoClick(index)"
                >EXCLUIR</v-btn
              >
              <v-btn
                class="ma-1"
                color="blue-grey"
                outlined
                @click="btnEditarProdutoClick(index)"
                >EDITAR</v-btn
              >
            </template>
          </v-data-table>
        </div>
      </v-card>
    </v-dialog>
    <!-- Fim do dialog dos itens -->
    <!-- Dialog do produto -->
    <v-dialog
      fullscreen
      transition="dialog-bottom-transition"
      v-model="bolDialogProduto"
      max-width="290"
    >
      <v-card>
        <v-toolbar dark color="blue-grey darken-4">
          <v-btn icon @click.stop="bolDialogProduto = false">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-toolbar-title>ITEM DO ORÇAMENTO</v-toolbar-title>
        </v-toolbar>
        <v-card class="pa-2">
          <v-form ref="form" lazy-validation>
            <v-col>
              <v-select
                v-model="tfilhoregistro.N_PRO_MARCA"
                :items="qTracos"
                item-text="NOME_PRO"
                item-value="N_PRO_MARCA"
                label="Produto"
                dense
                required
              ></v-select>
            </v-col>
            <v-col>
              <v-text-field
                v-model="tfilhoregistro.QTDE_ITEM"
                label="Qtde"
                required
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="tfilhoregistro.VALORUNIT_ITEM"
                label="Valor unitário"
                required
              ></v-text-field>
            </v-col>
            <v-col>
              <v-textarea
                v-model="tfilhoregistro.OBS_ITEM"
                outlined
                label="Observações"
              ></v-textarea>
            </v-col>
            <v-col>
              <v-row class="ma-1" justify="center">
                <v-btn
                  color="blue-grey darken-4"
                  dark
                  @click="btnSalvarProdutoClick"
                >
                  Salvar
                </v-btn>
              </v-row>
            </v-col>
          </v-form>
        </v-card>
      </v-card>
    </v-dialog>
    <!-- Fim do dialog do produto -->
    <v-snackbar v-model="snackbar" timeout="4000">
      {{ snackbartext }}
    </v-snackbar>
  </v-container>
</template>

<script>
import api from "../services/api.js";
import viacep from "../services/viacep.js";

export default {
  name: "Orçamentos",

  data() {
    return {
      //veriaveis
      edPesquisa: "",
      strStatusRegistro: "",
      strStatusItem: "",
      snackbar: false,
      snackbartext: "",
      isLoading: false,
      isLoadingCEP: false,
      bolMostraGrid: false,
      bolPerguntaExcluir: false,
      bolPerguntaExcluirItem: false,
      bolDialogPesqCliente: false,
      bolMostraGridCliente: false,
      bolDialogItens: false,
      bolMostraGridItens: false,
      bolDialogProduto: false,
      bolPDF: false,
      edPesquisaCliente: "",
      dataHoje: "",
      intIndexEditado: 0,
      //datasets
      tpai: {
        N_ORC: "",
        N_EMP: "",
        N_USU: "",
        NUMERO_ORC: "",
        DATA_ORC: "",
        HORA_ORC: "",
        N_PES_VENDEDOR: "",
        STATUS_ORC: "",
        N_PES_CLIENTE: "",
        NOME_ORC: "",
        CPFCNPJ_ORC: "",
        CONTATO_ORC: "",
        TEL_ORC: "",
        CEL_ORC: "",
        EMAIL_ORC: "",
        APLICACAO_CONCRETO: "",
        PRAZO_ORC: "",
        CONDICAO_ORC: "",
        N_TIP: "",
        PRAZOEXEC_ORC: "",
        INICIOPREV_ORC: "",
        VIGENCIA_ORC: "",
        CEP_OBRA: "",
        TIPOEND_OBRA: "",
        END_OBRA: "",
        NUMERO_OBRA: "",
        LATITUDE_CON: "",
        LONGITUDE_CON: "",
        COMPLEMENTO_OBRA: "",
        BAIRRO_OBRA: "",
        CIDADE_OBRA: "",
        CODIBGE_MUNICIPIO: "",
        UF_OBRA: "",
        OBSERVACAO: "",
        ITENS: [],
      },
      tfilho: [],
      tfilhoregistro: {
        N_ITEM: "",
        N_PRO: "",
        N_PRO_MARCA: "",
        N_USU: "",
        N_ORC: "",
        DATA_CAD: "",
        HORA_CAD: "",
        ORIGEM_CAD: "",
        DESCRICAO_ITEM: "",
        OBS_ITEM: "",
        QTDE_ITEM: "",
        VALORUNIT_ITEM: "",
        VALORTOTAL_ITEM: "",
        NOME_PRO: "",
        NOME_COMPLETO: "",
      },
      qConsulta: [],
      qGrid: [],
      qTipoCobranca: [],
      qGridClientes: [],
      qTracos: [],
      //headers das grids
      headers: [
        {
          text: "Orcamento",
          align: "start",
          value: "NUMERO_ORC",
        },
        { text: "Data", value: "DATA_ORC" },
        { text: "Vendedor", value: "NOME_VENDEDOR" },
        { text: "Valor", value: "VALORTOTAL_ORC" },
        { text: "Status", value: "STATUS_ORC" },
        { text: "Cliente", value: "NOME_ORC" },
        { text: "Endereço", value: "ENDCOMPLETO_OBRA" },
        { text: "Cidade", value: "CIDADE_OBRA" },
        { text: "", value: "botoes", sortable: false },
      ],
      headerscliente: [
        {
          text: "Razão Social",
          align: "start",
          value: "RAZAO_PES",
        },
        { text: "Nome Fantasia", value: "NOME_PES" },
        { text: "CPF/CNPJ", value: "CPFCNPJ_PES" },
        { text: "Endereço", value: "ENDCOMPLETO_PES" },
        { text: "Cidade/UF", value: "CIDADE_PES" },
        { text: "", value: "acoes", sortable: false },
      ],
      headersitens: [
        {
          text: "Produto",
          align: "start",
          value: "NOME_PRO",
        },
        { text: "Qtde", value: "QTDE_ITEM" },
        { text: "Valor unit.", value: "VALORUNIT_ITEM" },
        { text: "Obs:", value: "OBS_ITEM" },
        // { text: "Valor total", value: "VALORTOTAL_ITEM" },
        // { text: "Lançamento", value: "DATA_CAD" },
        // { text: "Usuário", value: "NOME_COMPLETO" },
        { text: "", value: "acoes", sortable: false },
      ],
      bolFiltraVendedor: false,
    };
  },
  mounted() {
    this.$store.state.vtitulojanela = "ORÇAMENTOS";
    this.getDataHoje();
    this.carregaTipoCobranca();
    this.filtravendedorlogado();
  },
  methods: {
    filtravendedorlogado() {
      api
        .get(
          "/v1/perfildeacessoitem?perfil=" +
            this.$store.state.intN_PER +
            "&tela=235"
        )
        .then((response) => {
          const valoritem1 = response.data.find(
            (x) => x.DESCRICAO_ITEM === "FILTRA ORÇAMENTOS DO VENDEDOR LOGADO"
          ).VALOR_ITEM;
          if (valoritem1 === "N") {
            this.bolFiltraVendedor = false;
          } else {
            this.bolFiltraVendedor = true;
          }
        });
    },
    btnExcluirProdutoClick(index) {
      this.tfilho.splice(index, 1);
    },
    btnEditarProdutoClick(index) {
      this.strStatusItem = "EDIT";
      this.intIndexEditado = index;
      this.tfilhoregistro = {};
      this.tfilhoregistro = this.tfilho[index];
      this.carregaTracos();
      this.bolDialogProduto = true;
    },
    btnSalvarProdutoClick() {
      let sql =
        "select p.n_pro, p.nome_pro " +
        "from produtos p ,produtos_marcas m " +
        "where p.n_pro = m.n_pro " +
        "and m.n_pro_marca = " +
        this.tfilhoregistro.N_PRO_MARCA;
      sql = encodeURIComponent(sql);
      api.get("/v1/consulta?sql=" + sql).then((response) => {
        this.tfilhoregistro.N_PRO = response.data[0].N_PRO;
        this.tfilhoregistro.NOME_PRO = response.data[0].NOME_PRO;
        if (this.strStatusItem === "INSERT") {
          this.tfilho.push({ ...this.tfilhoregistro });
          this.bolDialogProduto = false;
          this.bolMostraGridItens = true;
        } else {
          this.$set(this.tfilho, this.intIndexEditado, this.tfilhoregistro);
          this.bolDialogProduto = false;
          this.bolMostraGridItens = true;
        }
      });
    },
    carregaTracos() {
      let sql =
        "select p.n_pro, m.n_pro_marca, p.nome_pro " +
        "from produtos P ,produtos_marcas M " +
        "where p.n_pro = m.n_pro " +
        "and p.tipo_pro = 'VENDA' " +
        "and p.tipo_material in ('CONCRETO','ARGAMASSA','OUTROS') " +
        "and p.n_emp = " +
        this.$store.state.vempresa +
        " " +
        "and p.status_pro = 'ATIVO' " +
        "and m.status_marca = 'ATIVO' " +
        "order by p.nome_pro";
      sql = encodeURIComponent(sql);
      this.qTracos = [];
      api.get("/v1/consulta?sql=" + sql).then((response) => {
        this.qTracos = response.data;
      });
    },
    btnAdicionarProdutoClick() {
      this.strStatusItem = "INSERT";
      this.limpaTfilhoRegistro();
      this.carregaTracos();
      this.tfilhoregistro.N_ITEM = "0"; //indica insert
      this.tfilhoregistro.N_ORC = this.tpai.N_ORC;
      this.tfilhoregistro.N_USU = this.$store.state.codusuario;
      this.bolDialogProduto = true;
    },
    btnMostrarItensClick() {
      this.bolMostraGridItens = false;
      this.bolDialogItens = true;
      this.btnConsultarItensClick();
    },
    btnConsultarItensClick() {
      if (this.tfilho.length === 0) {
        if (this.tpai.N_ORC !== "0") {
          //se o tfilho estiver vazio pesquisa na api, senão mantem os itens
          this.isLoading = true;
          let sql =
            "select i.n_item, i.n_pro, i.n_pro_marca, i.n_usu, i.n_orc, i.hora_cad, i.origem_cad, " +
            "i.data_cad, i.descricao_item, i.obs_item, i.qtde_item, cast(i.valorunit_item as float) valorunit_item, cast(i.valortotal_item as float) valortotal_item, " +
            "p.nome_pro, u.nome_completo " +
            "from orcconcreteiraitens i, produtos_marcas m, produtos p, usuario u " +
            "where i.n_pro_marca = m.n_pro_marca " +
            "and m.n_pro = p.n_pro " +
            "and i.n_usu = u.n_usu " +
            "and i.n_orc = " +
            this.tpai.N_ORC +
            " " +
            "order by p.nome_pro, i.qtde_item, i.valorunit_item";
          sql = encodeURIComponent(sql);
          this.tfilho = [];
          api.get("/v1/consulta?sql=" + sql).then((response) => {
            this.tfilho = response.data;
            this.bolMostraGridItens = true;
            this.isLoading = false;
          });
        } else {
          this.tfilho = [];
          this.bolMostraGridItens = false;
        }
      } else {
        this.bolMostraGridItens = true;
      }
    },
    btnPesquisarClienteClick() {
      this.edPesquisaCliente = "";
      this.qGridClientes = [];
      this.bolMostraGridCliente = false;
      this.bolDialogPesqCliente = true;
    },
    btnConsultarClienteClick() {
      if (this.edPesquisaCliente.length >= 3) {
        this.isLoading = true;
        let sql =
          "select p.n_pes, p.razao_pes, p.nome_pes, p.cep_pes, p.tipoend_pes, p.end_pes, p.numero_pes, p.tipoend_pes||' '||p.end_pes||', '||p.numero_pes ENDCOMPLETO_PES, " +
          "p.bairro_pes, p.cpfcnpj_pes, p.rgie_pes, p.tel1_pes, p.celular_pes, p.email_pes, p.cidade_pes, p.cidade_pes||' / '||p.uf_pes cidadeuf_pes, p.uf_pes, " +
          "p.codibge_municipio, p.complemento_pes " +
          "from adm_pessoa p " +
          "where p.tipopes_pes = 'CLIENTE'" +
          "and p.razao_pes like '%" +
          this.edPesquisaCliente +
          "%' " +
          "order by 2";
        sql = encodeURIComponent(sql);
        this.qGridClientes = [];
        api.get("/v1/consulta?sql=" + sql).then((response) => {
          this.qGridClientes = response.data;
          this.bolMostraGridCliente = true;
          this.isLoading = false;
        });
      }
    },
    btnSelecionarClienteClick(item) {
      this.bolDialogPesqCliente = false;
      this.tpai.N_PES_CLIENTE = item.N_PES;
      this.tpai.NOME_ORC = item.RAZAO_PES;
      this.tpai.CPFCNPJ_ORC = item.CPFCNPJ_PES;
      this.tpai.CONTATO_ORC = item.NOME_PES;
      this.tpai.TEL_ORC = item.TEL1_PES;
      this.tpai.CEL_ORC = item.CELULAR_PES;
      this.tpai.EMAIL_ORC = item.EMAIL_PES;
      this.tpai.CEP_OBRA = item.CEP_PES;
      this.tpai.TIPOEND_OBRA = item.TIPOEND_PES;
      this.tpai.END_OBRA = item.END_PES;
      this.tpai.NUMERO_OBRA = item.NUMERO_PES;
      this.tpai.COMPLEMENTO_OBRA = item.COMPLEMENTO_PES;
      this.tpai.BAIRRO_OBRA = item.BAIRRO_PES;
      this.tpai.CIDADE_OBRA = item.CIDADE_PES;
      this.tpai.CODIBGE_MUNICIPIO = item.CODIBGE_MUNICIPIO;
      this.tpai.UF_OBRA = item.UF_PES;
    },
    btnConsultaViaCEPClick() {
      if (this.tpai.CEP_OBRA != "") {
        //Nova variável "cep" somente com dígitos.
        var cep = this.tpai.CEP_OBRA.replace(/\D/g, "");
        //Expressão regular para validar o CEP.
        var validacep = /^[0-9]{8}$/;
        //Valida o formato do CEP.
        if (validacep.test(cep)) {
          this.isLoadingCEP = true;
          viacep.get("/" + cep + "/json/").then((response) => {
            if (response.data.erro === true) {
              this.isLoadingCEP = false;
              this.snackbartext = "CEP inválido";
              this.snackbar = true;
            } else {
              let strLogradouro = response.data.logradouro.toUpperCase();
              let strTipoLogradouro = strLogradouro.slice(
                0,
                strLogradouro.indexOf(" ")
              );
              let strEndLogradouro = strLogradouro.slice(
                strLogradouro.indexOf(" ") + 1
              );
              this.tpai.TIPOEND_OBRA = strTipoLogradouro;
              this.tpai.END_OBRA = strEndLogradouro;
              this.tpai.BAIRRO_OBRA = response.data.bairro.toUpperCase();
              this.tpai.CIDADE_OBRA = response.data.localidade.toUpperCase();
              this.tpai.UF_OBRA = response.data.uf.toUpperCase();
              this.tpai.CODIBGE_MUNICIPIO = response.data.ibge;
              this.isLoadingCEP = false;
            }
          });
        } else {
          this.isLoadingCEP = false;
          this.snackbartext = "CEP inválido";
          this.snackbar = true;
        }
      }
    },
    formataDataISO(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      return `${year}-${month}-${day}`;
    },
    getDataHoje: function () {
      const today = new Date().toISOString().substr(0, 10);
      this.dataHoje = today;
    },
    limpaTpai() {
      this.tpai.N_ORC = "";
      this.tpai.NUMERO_ORC = "";
      this.tpai.DATA_ORC = "";
      this.tpai.HORA_ORC = "";
      this.tpai.N_PES_VENDEDOR = "";
      this.tpai.STATUS_ORC = "";
      this.tpai.N_PES_CLIENTE = "";
      this.tpai.NOME_ORC = "";
      this.tpai.CPFCNPJ_ORC = "";
      this.tpai.CONTATO_ORC = "";
      this.tpai.TEL_ORC = "";
      this.tpai.CEL_ORC = "";
      this.tpai.EMAIL_ORC = "";
      this.tpai.APLICACAO_CONCRETO = "";
      this.tpai.PRAZO_ORC = "";
      this.tpai.CONDICAO_ORC = "";
      this.tpai.N_TIP = "";
      this.tpai.PRAZOEXEC_ORC = "";
      this.tpai.INICIOPREV_ORC = "";
      this.tpai.VIGENCIA_ORC = "";
      this.tpai.CEP_OBRA = "";
      this.tpai.TIPOEND_OBRA = "";
      this.tpai.END_OBRA = "";
      this.tpai.NUMERO_OBRA = "";
      this.tpai.LATITUDE_CON = "";
      this.tpai.LONGITUDE_CON = "";
      this.tpai.COMPLEMENTO_OBRA = "";
      this.tpai.BAIRRO_OBRA = "";
      this.tpai.CIDADE_OBRA = "";
      this.tpai.CODIBGE_MUNICIPIO = "";
      this.tpai.UF_OBRA = "";
      this.tpai.OBSERVACAO = "";
    },
    limpaTfilhoRegistro() {
      this.tfilhoregistro.N_ITEM = "";
      this.tfilhoregistro.N_PRO = "";
      this.tfilhoregistro.N_PRO_MARCA = "";
      this.tfilhoregistro.N_USU = "";
      this.tfilhoregistro.N_ORC = "";
      this.tfilhoregistro.DATA_CAD = "";
      this.tfilhoregistro.HORA_CAD = "";
      this.tfilhoregistro.ORIGEM_CAD = "";
      this.tfilhoregistro.DESCRICAO_ITEM = "";
      this.tfilhoregistro.OBS_ITEM = "";
      this.tfilhoregistro.QTDE_ITEM = "";
      this.tfilhoregistro.VALORUNIT_ITEM = "";
      this.tfilhoregistro.VALORTOTAL_ITEM = "";
      this.tfilhoregistro.NOME_PRO = "";
      this.tfilhoregistro.NOME_COMPLETO = "";
    },
    populaTpai(dataset) {
      this.tpai.N_ORC = dataset.N_ORC;
      this.tpai.N_EMP = dataset.N_EMP;
      this.tpai.N_USU = dataset.N_USU;
      this.tpai.NUMERO_ORC = dataset.NUMERO_ORC;
      this.tpai.DATA_ORC = this.formataDataISO(dataset.DATA_ORC);
      this.tpai.HORA_ORC = dataset.HORA_ORC;
      this.tpai.N_PES_VENDEDOR = dataset.N_PES_VENDEDOR;
      this.tpai.STATUS_ORC = dataset.STATUS_ORC;
      this.tpai.N_PES_CLIENTE = dataset.N_PES_CLIENTE;
      this.tpai.NOME_ORC = dataset.NOME_ORC;
      this.tpai.CPFCNPJ_ORC = dataset.CPFCNPJ_ORC;
      this.tpai.CONTATO_ORC = dataset.CONTATO_ORC;
      this.tpai.TEL_ORC = dataset.TEL_ORC;
      this.tpai.CEL_ORC = dataset.CEL_ORC;
      this.tpai.EMAIL_ORC = dataset.EMAIL_ORC;
      this.tpai.APLICACAO_CONCRETO = dataset.APLICACAO_CONCRETO;
      this.tpai.PRAZO_ORC = dataset.PRAZO_ORC;
      this.tpai.CONDICAO_ORC = dataset.CONDICAO_ORC;
      this.tpai.N_TIP = dataset.N_TIP;
      this.tpai.PRAZOEXEC_ORC = dataset.PRAZOEXEC_ORC;
      this.tpai.INICIOPREV_ORC = this.formataDataISO(dataset.INICIOPREV_ORC);
      this.tpai.VIGENCIA_ORC = dataset.VIGENCIA_ORC;
      this.tpai.CEP_OBRA = dataset.CEP_OBRA;
      this.tpai.TIPOEND_OBRA = dataset.TIPOEND_OBRA;
      this.tpai.END_OBRA = dataset.END_OBRA;
      this.tpai.NUMERO_OBRA = dataset.NUMERO_OBRA;
      this.tpai.LATITUDE_CON = dataset.LATITUDE_CON;
      this.tpai.LONGITUDE_CON = dataset.LONGITUDE_CON;
      this.tpai.COMPLEMENTO_OBRA = dataset.COMPLEMENTO_OBRA;
      this.tpai.BAIRRO_OBRA = dataset.BAIRRO_OBRA;
      this.tpai.CIDADE_OBRA = dataset.CIDADE_OBRA;
      this.tpai.CODIBGE_MUNICIPIO = dataset.CODIBGE_MUNICIPIO;
      this.tpai.UF_OBRA = dataset.UF_OBRA;
      this.tpai.OBSERVACAO = dataset.OBSERVACAO;
    },
    btnIncluirClick() {
      if (this.$store.state.codfuncionario == "") {
        this.snackbartext = "Vendedor não configurado";
        this.snackbar = true;
      } else {
        this.limpaTpai();
        this.tpai.N_ORC = "0"; //indica insert
        this.tpai.INICIOPREV_ORC = this.dataHoje;
        this.tpai.N_EMP = this.$store.state.vempresa;
        this.tpai.N_USU = this.$store.state.codusuario;
        this.tpai.STATUS_ORC = "AGUARDANDO";
        this.tpai.N_PES_VENDEDOR = this.$store.state.codfuncionario;
        this.strStatusRegistro = "INSERT";
        this.tfilho = [];
      }
    },
    btnPDFClick(item) {
      var url =
        "http://" +
        this.$store.state.baseUSER +
        ":" +
        this.$store.state.basePASS +
        "@" +
        this.$store.state.baseIP +
        ":" +
        this.$store.state.basePORT +
        "/v1/orcamentoconcreteirapdf?N_ORC=" +
        item.N_ORC;
      window.open(url);
    },
    btnAlterarClick(item) {
      let sql =
        "select o.* " +
        "from orcconcreteira o " +
        "where o.n_orc = " +
        item.N_ORC;
      sql = encodeURIComponent(sql);
      api.get("/v1/consulta?sql=" + sql).then((response) => {
        this.populaTpai(response.data[0]);
        this.strStatusRegistro = "EDIT";
        this.tfilho = [];
      });
    },
    btnCancelarClick() {
      this.limpaTpai();
      this.strStatusRegistro = "";
    },
    btnExcluirClick() {
      api
        .delete("/v1/orcamentoconcreteira", {
          data: JSON.stringify(this.tpai, this.replacer).toUpperCase(),
        })
        .then((response) => {
          if (response.data.RESULTADO === "OK") {
            window.scrollTo(0, 0);
            this.bolMostraGrid = true;
            this.strStatusRegistro = "";
            this.btnPesquisarClick();
          } else {
            this.snackbartext = response.data.ERRO;
            this.snackbar = true;
          }
        });
    },
    btnSalvarClick() {
      if (this.$refs.form.validate()) {
        if (this.tfilho.length === 0 && this.strStatusRegistro === "INSERT") {
          this.snackbartext = "Nenhum produto inserido";
          this.snackbar = true;
        } else {
          this.tpai.ITENS = this.tfilho;
          api
            .post(
              "/v1/orcamentoconcreteira",
              JSON.stringify(this.tpai, this.replacer).toUpperCase()
            )
            .then((response) => {
              if (response.data.RESULTADO === "OK") {
                window.scrollTo(0, 0);
                this.bolMostraGrid = true;
                this.strStatusRegistro = "";
                this.btnPesquisarClick();
              } else {
                this.snackbartext = response.data;
                this.snackbar = true;
              }
            });
        }
      }
    },
    btnPesquisarClick() {
      if (this.$store.state.codfuncionario == "") {
        this.snackbartext = "Vendedor não configurado";
        this.snackbar = true;
      } else {
        this.isLoading = true;
        let sql =
          "select o.n_orc, o.n_emp, o.endcompleto_obra, o.cidade_obra||' / '||o.uf_obra cidade_obra, o.nome_orc, o.data_cad, o.hora_cad, u.nome_completo," +
          "o.numero_orc, o.data_orc, o.origem_cad, v.razao_pes nome_vendedor, o.email_orc, o.n_pes_cliente, o.status_orc, " +
          "cast((select coalesce(sum(i.valortotal_item),0) from orcconcreteiraitens i where i.n_orc = o.n_orc) as float) VALORTOTAL_ORC, " +
          "(select cast(list(c.numero_contrato) as varchar(50)) from contratos c where c.n_orc = o.n_orc) CONTRATOGERADO " +
          "from orcconcreteira o " +
          "left outer join adm_pessoa p on p.n_pes = o.n_pes_cliente " +
          ", usuario u, adm_pessoa v " +
          "where o.n_usu = u.n_usu " +
          "and o.n_pes_vendedor = v.n_pes " +
          "and o.n_emp = " +
          this.$store.state.vempresa +
          " " +
          "and ((o.endcompleto_obra like '%" +
          this.edPesquisa +
          "%') " +
          " or (o.cidade_obra like '%" +
          this.edPesquisa +
          "%') " +
          " or (o.nome_orc like '%" +
          this.edPesquisa +
          "%') " +
          " or (o.uf_obra like '%" +
          this.edPesquisa +
          "%')) ";

        if (this.bolFiltraVendedor == true) {
          sql =
            sql +
            "and o.n_pes_vendedor = " +
            this.$store.state.codfuncionario +
            " ";
        }

        sql = sql + "order by o.n_orc desc";

        sql = encodeURIComponent(sql);
        this.qGrid = [];
        api.get("/v1/consulta?sql=" + sql).then((response) => {
          this.qGrid = response.data;
          this.bolMostraGrid = true;
          this.isLoading = false;
        });
      }
    },
    carregaTipoCobranca() {
      let sql =
        "select t.n_tip, t.descricao " +
        "from tipo_cobranca t " +
        "where t.tipo = 'C' " +
        "order by 2";
      sql = encodeURIComponent(sql);
      this.qTipoCobranca = [];
      api.get("/v1/consulta?sql=" + sql).then((response) => {
        this.qTipoCobranca = response.data;
      });
    },
    replacer(key, value) {
      // Filtering out properties
      if (value === null) {
        return "";
      }
      return value;
    },
  },
};
</script>