<template>
  <v-container fluid class="d-flex flex-column">
    <div class="pb-2 text-center text-h6">
      <v-card max-width="500px" class="blue-grey lighten-5">
        <v-col>
          <v-text-field
            v-model="dtData1"
            hide-details
            single-line
            type="date"
            required
            prefix="De: "
          ></v-text-field>
          <v-text-field
            v-model="dtData2"
            hide-details
            single-line
            type="date"
            required
            prefix="Até: "
          ></v-text-field>
        </v-col>
        <v-col>
          <v-btn color="primary" :loading="isloading" @click="consultarapi()">
            Pesquisar
          </v-btn>
        </v-col>
      </v-card>
    </div>
    <div v-if="mostradados === true" class="pb-2">
      <v-card max-width="500px" color="light-blue darken-3" dark>
        <v-card-title class="text-h5">Concreto</v-card-title>
        <v-col>
          <v-row dense>
            <v-col> OS: </v-col>
            <v-col class="text-right">
              {{ dadosConsulta.concretoos }}
            </v-col>
          </v-row>
          <v-row dense>
            <v-col> M3: </v-col>
            <v-col class="text-right"> {{ dadosConsulta.concretoqtde }} </v-col>
          </v-row>
          <v-row dense>
            <v-col> Total R$ </v-col>
            <v-col class="text-right">
              R$ {{ dadosConsulta.concretovalor }}
            </v-col>
          </v-row>
          <v-row dense>
            <v-col> Custo R$ </v-col>
            <v-col class="text-right">
              R$ {{ dadosConsulta.concretocusto }}
            </v-col>
          </v-row>
          <v-row dense>
            <v-col> Lucro previsto R$ </v-col>
            <v-col class="text-right">
              R$ {{ dadosConsulta.concretolucro }}
            </v-col>
          </v-row>
          <v-row dense>
            <v-col> Ticket médio R$ </v-col>
            <v-col class="text-right">
              R$ {{ dadosConsulta.concretoticket }}
            </v-col>
          </v-row>
        </v-col>
      </v-card>
    </div>
    <div v-if="mostradados === true" class="pb-2">
      <v-card max-width="500px" color="#1F7087" dark>
        <v-card-title class="text-h5">Argamassa</v-card-title>
        <v-col>
          <v-row dense>
            <v-col> OS: </v-col>
            <v-col class="text-right"> {{ dadosConsulta.argamassaos }} </v-col>
          </v-row>
          <v-row dense>
            <v-col> M3: </v-col>
            <v-col class="text-right">
              {{ dadosConsulta.argamassaqtde }}
            </v-col>
          </v-row>
          <v-row dense>
            <v-col> Total R$ </v-col>
            <v-col class="text-right">
              R$ {{ dadosConsulta.argamassavalor }}
            </v-col>
          </v-row>
          <v-row dense>
            <v-col> Custo R$ </v-col>
            <v-col class="text-right">
              R$ {{ dadosConsulta.argamassacusto }}
            </v-col>
          </v-row>
          <v-row dense>
            <v-col> Lucro previsto R$ </v-col>
            <v-col class="text-right">
              R$ {{ dadosConsulta.argamassalucro }}
            </v-col>
          </v-row>
          <v-row dense>
            <v-col> Ticket médio R$ </v-col>
            <v-col class="text-right">
              R$ {{ dadosConsulta.argamassaticket }}
            </v-col>
          </v-row>
        </v-col>
      </v-card>
    </div>
    <div v-if="mostradados === true">
      <v-card max-width="500px" color="#546E7A" dark>
        <v-card-title class="text-h5">Bomba</v-card-title>
        <v-col>
          <v-row dense>
            <v-col> OS: </v-col>
            <v-col class="text-right"> {{ dadosConsulta.bombaos }} </v-col>
          </v-row>
          <v-row dense>
            <v-col> Total R$ </v-col>
            <v-col class="text-right">
              R$ {{ dadosConsulta.bombavalor }}
            </v-col>
          </v-row>
          <v-row dense>
            <v-col> Ticket médio R$ </v-col>
            <v-col class="text-right">
              R$ {{ dadosConsulta.bombaticket }}
            </v-col>
          </v-row>
        </v-col>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import api from "../services/api.js";

export default {
  name: "Dashboard",
  data() {
    return {
      isloading: false,
      mostradados: false,
      dtData1: "",
      dtData2: "",
      dadosConsulta: [],
      datahoje: "",
    };
  },
  created() {
    this.mostradados = false;
  },
  mounted() {
    this.$store.state.vtitulojanela = "DASHBOARD";
    this.getNow();
    this.dtData1 = this.datahoje;
    this.dtData2 = this.datahoje;
  },
  methods: {
    getNow: function () {
      const today = new Date().toISOString().substr(0, 10);
      this.datahoje = today;
    },
    formatDateBR(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    consultarapi() {
      if (this.dtData1 != "" && this.dtData2 != "") {
        this.isloading = true;
        this.dadosConsulta = [];
        //console.log(this.formatDateBR(this.dtData1));
        //console.log(this.formatDateBR(this.dtData2));
        api
          .get(
            "/v1/dashconcreteira?data1=" +
              this.formatDateBR(this.dtData1) +
              "&data2=" +
              this.formatDateBR(this.dtData2) +
              "&empresa=" +
              this.$store.state.vempresa
          )
          .then((response) => {
            this.dadosConsulta = response.data;
            this.isloading = false;
            this.mostradados = true;
          });
      }
    },
  },
};
</script>