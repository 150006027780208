<template>
  <v-container fluid class="d-flex flex-column">
    <div class="pa-2">
      <v-toolbar max-width="500px" class="blue-grey lighten-5">
        <v-text-field
          v-model="edpesquisa"
          hide-details
          single-line
          label="Número da O.S."
        ></v-text-field>
        <v-btn :loading="isloading" icon @click="consultarapi()">
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </v-toolbar>
    </div>
    <div v-if="mostradados === true" class="pb-2">
      <v-card class="text-caption" elevation="0">
        <v-card class="d-flex justify-space-around" elevation="0">
          <v-card elevation="0">O.S.: {{ dadosOS[0].N_ORD }}</v-card>
          <v-card elevation="0">Data: {{ dadosOS[0].DATA_OS }}</v-card>
          <v-card elevation="0"
            >Contrato: {{ dadosOS[0].NUMERO_CONTRATO }}</v-card
          >
        </v-card>
        <v-card class="d-flex justify-space-around" elevation="0">
          <v-card elevation="0">FCK: {{ dadosOS[0].FCK }}</v-card>
          <v-card elevation="0">Slump: {{ dadosOS[0].SLUMP }}</v-card>
        </v-card>
        <v-card class="d-flex justify-space-around" elevation="0">
          <v-card elevation="0">Traço: {{ dadosOS[0].NOME_PRO }}</v-card>
        </v-card>
        <v-card class="d-flex justify-space-around" elevation="0">
          <v-card elevation="0">Cliente: {{ dadosOS[0].RAZAO_PES }}</v-card>
        </v-card>
      </v-card>
      <v-card elevation="0">
        <v-card-text class="py-0">
          <div class="pt-3">
            <v-timeline align-top>
              <v-timeline-item
                small
                right
                v-for="dadoConsulta in dadosConsulta"
                :key="dadoConsulta.N_PRO"
                :color="
                  dadoConsulta.TIPO_MP.slice(0, 4) == 'BRIT'
                    ? 'grey lighten-1'
                    : dadoConsulta.TIPO_MP.slice(0, 4) == 'AREI'
                    ? 'amber lighten-4'
                    : dadoConsulta.TIPO_MP.slice(0, 4) == 'AGUA'
                    ? 'light-blue lighten-4'
                    : dadoConsulta.TIPO_MP.slice(0, 4) == 'CIME'
                    ? 'blue-grey lighten-1'
                    : dadoConsulta.TIPO_MP.slice(0, 4) == 'ADIT'
                    ? 'green lighten-1'
                    : ''
                "
              >
                <span slot="opposite"
                  ><strong>{{ dadoConsulta.DESCRICAO }}</strong></span
                >
                <v-col>
                  <strong>{{
                    dadoConsulta.SOMA.toString().replace(".", ",")
                  }}</strong>
                </v-col>
              </v-timeline-item>
            </v-timeline>
          </div>
        </v-card-text>
      </v-card>
    </div>
    <v-snackbar v-model="snackbar" timeout="4000">
      {{ snackbartext }}
    </v-snackbar>
  </v-container>
</template>

<script>
import api from "../services/api.js";

export default {
  name: "Agregados",
  data() {
    return {
      isloading: false,
      mostradados: false,
      edpesquisa: "",
      dadosConsulta: [],
      dadosOS: [],
      datahoje: "",
      snackbar: false,
      snackbartext: "",
    };
  },
  created() {
    this.mostradados = false;
  },
  mounted() {
    this.$store.state.vtitulojanela = "AGREGADOS";
    this.getNow();
    this.dtData1 = this.datahoje;
    this.dtData2 = this.datahoje;
  },
  methods: {
    getNow: function () {
      const today = new Date().toISOString().substr(0, 10);
      this.datahoje = today;
    },
    formatDateBR(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    consultarapi() {
      if (this.edpesquisa.length > 8) {
        this.snackbartext = "Número inválido";
        this.snackbar = true;
      } else if (this.edpesquisa != "") {
        this.isloading = true;
        this.mostradados = false;
        //dados da OS
        this.sqlConsulta =
          "select o.n_ord, o.data_os, o.qtde, c.numero_contrato, p.nome_pro, p.fck, p.slump, m.codfabrica_marca, a.razao_pes " +
          "from ordem_servico o, produtos p, produtos_marcas m, contratos c, adm_pessoa a " +
          "where o.n_pro = p.n_pro " +
          "and p.n_pro = m.n_pro " +
          "and o.n_con = c.n_con " +
          "and c.n_pes_cliente = a.n_pes " +
          "and o.n_ord = " +
          this.edpesquisa;
        this.sqlConsulta = encodeURIComponent(this.sqlConsulta);
        this.dadosConsulta = [];
        api.get("/v1/consulta?sql=" + this.sqlConsulta).then((response) => {
          this.dadosOS = response.data;
        });
        //dados de carregamento
        this.sqlConsulta =
          "select s.* from sp_agregado_compacto2(" + this.edpesquisa + ") s";
        this.sqlConsulta = encodeURIComponent(this.sqlConsulta);
        this.dadosConsulta = [];
        api.get("/v1/consulta?sql=" + this.sqlConsulta).then((response) => {
          this.dadosConsulta = response.data;
          this.isloading = false;
          if (this.dadosConsulta.length > 0) {
            this.mostradados = true;
          } else {
            this.snackbartext = "O.S. não encontrada";
            this.snackbar = true;
          }
        });
      }
    },
  },
};
</script>