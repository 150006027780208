<template>
  <v-app>
    <v-app-bar
      v-model="this.$store.state.boolMostraAppBar"
      app
      color="primary"
      dark
    >
      <v-app-bar-nav-icon
        @click="boolMostraMenu = !boolMostraMenu"
      ></v-app-bar-nav-icon>
      <v-toolbar-title>{{ this.$store.state.vtitulojanela }}</v-toolbar-title>
    </v-app-bar>
    <!-- menu lateral -->
    <v-navigation-drawer
      v-model="boolMostraMenu"
      app
      clipped
      temporary
      width="auto"
      :touchless="booltouchless"
    >
      <v-list-item dark class="primary">
        <v-list-item-icon>
          <v-icon large @click="boolMostraMenu = !boolMostraMenu"
            >mdi-arrow-left-circle</v-icon
          >
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="title">
            {{ $store.state.vempresanome }}
          </v-list-item-title>
          <v-list-item-title>
            {{ $store.state.usuario }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ $store.state.strDESCRICAO_PER }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list-item link to="/" @click.stop="escondeAppBar">
        <v-list-item-icon>
          <v-icon>mdi-login-variant</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Trocar de empresa</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        v-if="this.$store.state.qEmpresa.TIPO_EMPRESA === 'CONCRETEIRA'"
        link
        @click="acessarDashboard()"
      >
        <v-list-item-icon>
          <v-icon>mdi-monitor-dashboard</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Dashboard</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item link @click="acessarClientes()">
        <v-list-item-icon>
          <v-icon>mdi-account-box-multiple</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Clientes</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        v-if="this.$store.state.qEmpresa.TIPO2_EMPRESA === 'DEPOSITO'"
        link
        @click="acessarProdutosDeposito()"
      >
        <v-list-item-icon>
          <v-icon>mdi-sitemap-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Produtos</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        v-if="this.$store.state.qEmpresa.TIPO_EMPRESA === 'CONCRETEIRA'"
        link
        @click="acessarOrcamentos()"
      >
        <v-list-item-icon>
          <v-icon>mdi-calculator</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Orçamentos</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        v-if="this.$store.state.qEmpresa.TIPO_EMPRESA === 'CONCRETEIRA'"
        link
        @click="acessarContratos()"
      >
        <v-list-item-icon>
          <v-icon>mdi-file-document-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Contratos</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        v-if="this.$store.state.qEmpresa.TIPO_EMPRESA === 'CONCRETEIRA'"
        link
        @click="acessarOs()"
      >
        <v-list-item-icon>
          <v-icon>mdi-dump-truck</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Ordens de serviço</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        v-if="this.$store.state.qEmpresa.TIPO_EMPRESA === 'CONCRETEIRA'"
        link
        @click="acessarAgregados()"
      >
        <v-list-item-icon>
          <v-icon>mdi-scale</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Agregados</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        v-if="this.$store.state.qEmpresa.TIPO_EMPRESA === 'CONCRETEIRA'"
        link
        @click="acessarTracos()"
      >
        <v-list-item-icon>
          <v-icon>mdi-sitemap-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Traços</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        v-if="this.$store.state.qEmpresa.TIPO_EMPRESA === 'CONCRETEIRA'"
        link
        @click="acessarCorpoprova()"
      >
        <v-list-item-icon>
          <v-icon>mdi-cylinder</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Corpo de prova</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        v-if="this.$store.state.qEmpresa.TIPO_EMPRESA === 'CONCRETEIRA'"
        link
        @click="acessarProgramacao()"
      >
        <v-list-item-icon>
          <v-icon>mdi-clipboard-list-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Programação de entrega</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item link @click="acessarMarketing()">
        <v-list-item-icon>
          <v-icon>mdi-account-box-multiple</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Cliente marketing</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-navigation-drawer>

    <v-main>
      <router-view />
    </v-main>

    <v-snackbar v-model="snackbar" timeout="4000">
      {{ snackbartext }}
    </v-snackbar>
  </v-app>
</template>

<script>
import api from "./services/api.js";
import router from "./router";
//import axios from "axios";

export default {
  name: "App",

  data() {
    return {
      boolMostraMenu: false,
      booltouchless: true,
      snackbartext: "",
      snackbar: false,
    };
  },

  methods: {
    escondeAppBar() {
      this.$store.state.boolMostraAppBar = false;
    },
    acessarDashboard() {
      api
        .get(
          "/v1/perfildeacesso?perfil=" +
            this.$store.state.intN_PER +
            "&tela=216"
        )
        .then((response) => {
          if (response.data.ACESSAR === "S") {
            this.$store.state.qPerfil = response.data;
            router.push({ path: "/dashboard" });
          } else {
            this.snackbartext = "Perfil de acesso: Negado";
            this.snackbar = true;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    acessarOrcamentos() {
      api
        .get(
          "/v1/perfildeacesso?perfil=" +
            this.$store.state.intN_PER +
            "&tela=235"
        )
        .then((response) => {
          if (response.data.ACESSAR === "S") {
            this.$store.state.qPerfil = response.data;
            router.push({ path: "/orcamentos" });
          } else {
            this.snackbartext = "Perfil de acesso: Negado";
            this.snackbar = true;
          }
        });
    },
    acessarTracos() {
      router.push({ path: "/tracos" });
    },
    acessarProdutosDeposito() {
      api
        .get(
          "/v1/perfildeacesso?perfil=" + this.$store.state.intN_PER + "&tela=3"
        )
        .then((response) => {
          if (response.data.ACESSAR === "S") {
            this.$store.state.qPerfil = response.data;
            router.push({ path: "/produtosdeposito" });
          } else {
            this.snackbartext = "Perfil de acesso: Negado";
            this.snackbar = true;
          }
        });
    },
    acessarCorpoprova() {
      router.push({ path: "/corpoprova" });
    },
    acessarClientes() {
      api
        .get(
          "/v1/perfildeacesso?perfil=" + this.$store.state.intN_PER + "&tela=1"
        )
        .then((response) => {
          if (response.data.ACESSAR === "S") {
            this.$store.state.qPerfil = response.data;
            router.push({ path: "/pessoas" });
          } else {
            this.snackbartext = "Perfil de acesso: Negado";
            this.snackbar = true;
          }
        });
    },
    acessarMarketing() {
      api
        .get(
          "/v1/perfildeacesso?perfil=" +
            this.$store.state.intN_PER +
            "&tela=133"
        )
        .then((response) => {
          if (response.data.ACESSAR === "S") {
            this.$store.state.qPerfil = response.data;
            router.push({ path: "/marketing" });
          } else {
            this.snackbartext = "Perfil de acesso: Negado";
            this.snackbar = true;
          }
        });
    },
    acessarContratos() {
      api
        .get(
          "/v1/perfildeacesso?perfil=" + this.$store.state.intN_PER + "&tela=93"
        )
        .then((response) => {
          if (response.data.ACESSAR === "S") {
            this.$store.state.qPerfil = response.data;
            router.push({ path: "/contratos" });
          } else {
            this.snackbartext = "Perfil de acesso: Negado";
            this.snackbar = true;
          }
        });
    },
    acessarProgramacao() {
      api
        .get(
          "/v1/perfildeacesso?perfil=" +
            this.$store.state.intN_PER +
            "&tela=190"
        )
        .then((response) => {
          if (response.data.ACESSAR === "S") {
            this.$store.state.qPerfil = response.data;
            router.push({ path: "/programacao" });
          } else {
            this.snackbartext = "Perfil de acesso: Negado";
            this.snackbar = true;
          }
        });
    },
    acessarOs() {
      api
        .get(
          "/v1/perfildeacesso?perfil=" + this.$store.state.intN_PER + "&tela=94"
        )
        .then((response) => {
          if (response.data.ACESSAR === "S") {
            this.$store.state.qPerfil = response.data;
            router.push({ path: "/os" });
          } else {
            this.snackbartext = "Perfil de acesso: Negado";
            this.snackbar = true;
          }
        });
    },
    acessarAgregados() {
      api
        .get(
          "/v1/perfildeacesso?perfil=" + this.$store.state.intN_PER + "&tela=94"
        )
        .then((response) => {
          if (response.data.ACESSAR === "S") {
            this.$store.state.qPerfil = response.data;
            router.push({ path: "/agregados" });
          } else {
            this.snackbartext = "Perfil de acesso: Negado";
            this.snackbar = true;
          }
        });
    },
  },
};
</script>
