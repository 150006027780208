<template>
  <v-container fluid class="d-flex flex-column">
    <div class="pb-2 text-center text-h6">
      <v-card max-width="500px" class="blue-grey lighten-5">
        <v-col>
          <v-text-field
            v-model="dtData1"
            hide-details
            single-line
            type="date"
            required
            prefix="De: "
          ></v-text-field>
          <v-text-field
            v-model="dtData2"
            hide-details
            single-line
            type="date"
            required
            prefix="Até: "
          ></v-text-field>
          <v-text-field
            v-model="edpesquisa"
            hide-details
            single-line
            label="OS/Cliente/Vendedor/Motorista/Veículo"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-btn color="primary" :loading="isloading" @click="consultarapi()">
            Pesquisar
          </v-btn>
        </v-col>
      </v-card>
    </div>
    <div class="pa-2">
      <v-data-table
        v-if="bolMostraGrid"
        :headers="headers"
        :items="dadosConsulta"
        sort-by="nome"
        class="elevation-2 grey lighten-4"
        disable-sort
        disable-pagination
        hide-default-footer
        dense
        loading-text="Carregando..."
        no-data-text="Nenhum registro encontrado"
      >
      </v-data-table>
    </div>
  </v-container>
</template>

<script>
import api from "../services/api.js";

export default {
  name: "Os",
  data() {
    return {
      isloading: false,
      datahoje: "",
      dtData1: "",
      dtData2: "",
      dadosConsulta: [],
      edpesquisa: "",
      sqlConsulta: "",
      bolMostraGrid: false,
      headers: [
        {
          text: "OS",
          align: "start",
          value: "N_ORD",
        },
        { text: "Cliente", value: "NOME_CLIENTE" },
        { text: "Obra", value: "ENDCOMPLETO_OBRA" },
        { text: "Qtde", value: "QTDE" },
        { text: "Total R$", value: "VALOR_TOTAL" },
        { text: "Prod/Serv.", value: "NOME_PRO" },
        { text: "Vendedor", value: "NOME_VENDEDOR" },
        { text: "Veículo", value: "CODIGO_VEICULO" },
        { text: "Motorista", value: "NOME_MOTORISTA" },
      ],
    };
  },
  mounted() {
    this.$store.state.vtitulojanela = "ORDENS DE SERVIÇO";
    this.getNow();
    this.dtData1 = this.datahoje;
    this.dtData2 = this.datahoje;
  },
  methods: {
    sumField(key) {
      // sum data in give key (property)
      return this.dadosConsulta.reduce((a, b) => a + (b[key] || 0), 0);
    },
    getNow: function () {
      const today = new Date().toISOString().substr(0, 10);
      this.datahoje = today;
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    consultarapi() {
      if (this.dtData1 != "" && this.dtData2 != "") {
        this.isloading = true;
        this.sqlConsulta =
          "select o.n_ord||' - '||substring(100+extract(day from o.data_os) from 2 for 2)" +
          "||'/'||substring(100+extract(month from o.data_os) from 2 for 2)" +
          "||'/'||extract(year from o.data_os)" +
          "||' - '||substring(o.hora_os from 1 for 8) N_ORD, " +
          "o.data_os, o.hora_os, o.qtde, cast(o.valor_total as float) valor_total, p.nome_pro, " +
          "a1.razao_pes NOME_CLIENTE, a2.razao_pes NOME_VENDEDOR, a3.razao_pes NOME_MOTORISTA, u.nome_completo NOME_USUARIO, " +
          "v.placa||' / '||v.codigo_veiculo codigo_veiculo, " +
          "c.endcompleto_obra||' / '||c.bairro_obra||' / '||c.cidade_obra||' / '||c.uf_obra endcompleto_obra " +
          "from ordem_servico o " +
          "left outer join veiculos v on v.n_vei = o.n_vei " +
          "left outer join adm_pessoa a3 on a3.n_pes = o.n_pes_motorista " +
          ", adm_pessoa a1, adm_pessoa a2, produtos p, usuario u, contratos c " +
          "where o.n_pes_cliente = a1.n_pes " +
          "and o.n_pes_vendedor = a2.n_pes " +
          "and o.n_pro = p.n_pro " +
          "and o.n_usu = u.n_usu " +
          "and o.n_con = c.n_con " +
          "and o.data_os between '" +
          this.formatDate(this.dtData1) +
          "' and '" +
          this.formatDate(this.dtData2) +
          "' " +
          "and o.status = 'NORMAL' " +
          "and o.n_emp = " +
          this.$store.state.vempresa;

        if (this.edpesquisa != "") {
          this.sqlConsulta =
            this.sqlConsulta +
            "and ((o.n_ord like '%" +
            this.edpesquisa +
            "%') " +
            "or (p.nome_pro like '%" +
            this.edpesquisa +
            "%') " +
            "or (a1.razao_pes like '%" +
            this.edpesquisa +
            "%') " +
            "or (a2.razao_pes like '%" +
            this.edpesquisa +
            "%') " +
            "or (a3.razao_pes like '%" +
            this.edpesquisa +
            "%') " +
            "or (v.placa like '%" +
            this.edpesquisa +
            "%') " +
            "or (v.codigo_veiculo like '%" +
            this.edpesquisa +
            "%')) ";
        }
        this.sqlConsulta = this.sqlConsulta + "order by o.n_ord desc";
        this.sqlConsulta = encodeURIComponent(this.sqlConsulta);

        this.dadosConsulta = [];

        api.get("/v1/consulta?sql=" + this.sqlConsulta).then((response) => {
          this.dadosConsulta = response.data;
          this.isloading = false;
          this.bolMostraGrid = true;
        });
      }
    },
  },
};
</script>