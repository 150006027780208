import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    baseURL: null,
    baseIP: null,
    basePORT: null,
    baseUSER:"xsoftware",
    basePASS:"@cafe2007*",
    boolMostraAppBar: false,    
    codusuario: null,
    codfuncionario: null,
    usuario: null,
    vempresa: null,
    vempresanome: null,
    vtitulojanela: null,
    intN_PER: null,
    intN_PES: null,
    strDESCRICAO_PER: null,
    tipo_empresa: null,
    tipo2_empresa: null,
    assinatura_N_CON: null,
    assinatura_pesquisa: null,
    assinatura_b64: null,  
    qPerfil: [],    
    qEmpresa: [],
    itemsTipoEnd: [
      "RUA",
      "AVENIDA",
      "ESTRADA",
      "TRAVESSA",
      "RODOVIA",
      "PRAÇA",
      "ALAMEDA",
      "GALERIA",
      "CX POSTAL",
      "FAZENDA",
      "PONTE",
    ],
    itemsUF: [
      "AC",
      "AL",
      "AM",
      "AN",
      "AP",
      "BA",
      "CE",
      "DF",
      "ES",
      "EX",
      "GO",
      "MA",
      "MG",
      "MS",
      "MT",
      "PA",
      "PB",
      "PE",
      "PI",
      "PR",
      "RJ",
      "RN",
      "RO",
      "RR",
      "RS",
      "SC",
      "SE",
      "SP",
      "TO",
    ],
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
