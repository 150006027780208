import axios from "axios";

const speedio = axios.create({           
    baseURL: 'https://api-publica.speedio.com.br/buscarcnpj?cnpj=',    
    timeout: 10000,       
});

export default speedio;

// https://api-publica.speedio.com.br/buscarcnpj?cnpj=08863539000186

// {
//     "NOME FANTASIA": "X-SOFTWARE DESENVOLVIMENTO",
//     "RAZAO SOCIAL": "BOZA & PEREIRA LTDA",
//     "CNPJ": "08863539000186",
//     "STATUS": "ATIVA",
//     "CNAE PRINCIPAL DESCRICAO": "Desenvolvimento de programas de computador sob encomenda",
//     "CNAE PRINCIPAL CODIGO": "6201501",
//     "CEP": "86041286",
//     "DATA ABERTURA": "29/05/2007",
//     "DDD": "43",
//     "TELEFONE": "33245923",
//     "EMAIL": "ativa@sercomtel.com.br",
//     "TIPO LOGRADOURO": "RUA",
//     "LOGRADOURO": "JOSE NEGRI",
//     "NUMERO": "80",
//     "COMPLEMENTO": "CASA",
//     "BAIRRO": "JD. NEMAN SAHYUM",
//     "MUNICIPIO": "Londrina",
//     "UF": "PR"
//   }