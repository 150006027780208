<template>
  <v-container fluid class="d-flex flex-column">
    <!-- div da pesquisa -->
    <div class="pb-2 text-center text-h6">
      <v-card max-width="500px" class="blue-grey lighten-5">
        <v-col>
          Período das Ordens de Serviço
          <v-text-field
            v-model="dtData1"
            hide-details
            single-line
            type="date"
            required
            prefix="De: "
          ></v-text-field>
          <v-text-field
            v-model="dtData2"
            hide-details
            single-line
            type="date"
            required
            prefix="Até: "
          ></v-text-field>
          <v-text-field
            v-model="edpesquisa"
            hide-details
            single-line
            label="Contrato/Cliente/Vendedor"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-btn color="primary" :loading="isloading" @click="consultarapi()">
            Pesquisar
          </v-btn>
        </v-col>
      </v-card>
    </div>
    <div class="pa-2">
      <v-data-table
        v-if="bolMostraGrid"
        :headers="headers"
        :items="dadosConsulta"
        sort-by="nome"
        class="elevation-2"
        disable-sort
        disable-pagination
        hide-default-footer
        dense
        loading-text="Carregando..."
        no-data-text="Nenhum registro encontrado"
      >
      </v-data-table>
    </div>
    <!-- div da pesquisa -->
    <v-snackbar v-model="snackbar" timeout="4000">
      {{ snackbartext }}
    </v-snackbar>
  </v-container>
</template>

<script>
import api from "../services/api.js";

export default {
  name: "Corpoprova",

  data() {
    return {
      datahoje: "",
      dtData1: "",
      dtData2: "",
      snackbar: false,
      snackbartext: "",
      isloading: false,
      bolMostraGrid: false,
      sqlConsulta: "",
      dadosConsulta: [],
      edpesquisa: "",
      headers: [
        {
          text: "Contrato",
          align: "start",
          value: "NUMERO_CONTRATO",
        },
        { text: "Qtde CP à recolher", value: "QTDE_COR" },
        { text: "Cliente", value: "RAZAO_PES" },
        { text: "Obra", value: "ENDCOMPLETO_OBRA" },
        { text: "Bairro", value: "BAIRRO_OBRA" },
        { text: "Cidade", value: "CIDADE_OBRA" },
        { text: "UF", value: "UF_OBRA" },
        { text: "Vendedor", value: "VENDEDOR" },
      ],
    };
  },
  mounted() {
    this.$store.state.vtitulojanela = "CORPO DE PROVA";
    this.getNow();
    this.dtData1 = this.datahoje;
    this.dtData2 = this.datahoje;
  },
  methods: {
    getNow: function () {
      const today = new Date().toISOString().substr(0, 10);
      this.datahoje = today;
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    consultarapi() {
      this.isloading = true;
      this.sqlConsulta =
        "select c.n_con, c.numero_contrato, c.data_contrato, p.razao_pes, c.endcompleto_obra, c.bairro_obra, c.cidade_obra, c.uf_obra, vend.razao_pes vendedor, " +
        "count(cp.n_cor) QTDE_COR " +
        "from ordem_servico o, contratos c, adm_pessoa p, adm_pessoa vend, corpo_prova cp " +
        "where o.n_con = c.n_con " +
        "and c.n_pes_cliente = p.n_pes " +
        "and c.n_pes_vendedor = vend.n_pes " +
        "and o.n_ord = cp.n_ord " +
        "and o.n_emp = " +
        this.$store.state.vempresa +
        " " +
        "and o.status = 'NORMAL' " +
        "and o.tp_moldar = 'S' " +
        "and cp.data_coleta is null " +
        "and cp.resistencia is null " +
        "and o.data_os between '" +
        this.formatDate(this.dtData1) +
        "' and '" +
        this.formatDate(this.dtData2) +
        "' ";
      if (this.edpesquisa != "") {
        this.sqlConsulta =
          this.sqlConsulta +
          "and ((c.numero_contrato like '%" +
          this.edpesquisa +
          "%') " +
          "or (vend.razao_pes like '%" +
          this.edpesquisa +
          "%') " +
          "or (p.razao_pes like '%" +
          this.edpesquisa +
          "%')) ";
      }
      this.sqlConsulta = this.sqlConsulta + "group by 1,2,3,4,5,6,7,8,9";
      this.sqlConsulta = this.sqlConsulta + "order by 2";

      this.sqlConsulta = encodeURIComponent(this.sqlConsulta);
      //console.log(this.sqlConsulta);
      this.dadosConsulta = [];
      api.get("/v1/consulta?sql=" + this.sqlConsulta).then((response) => {
        //console.log(response.data);
        this.dadosConsulta = response.data;
        this.bolMostraGrid = true;
        this.isloading = false;
      });
    },
    replacer(key, value) {
      // Filtering out properties
      if (value === null) {
        return "";
      }
      return value;
    },
  },
};
</script>