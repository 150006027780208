<template>
  <v-container fluid class="d-flex flex-column">
    <!-- div da pesquisa -->

    <div class="pa-2">
      <v-toolbar max-width="500px" class="blue-grey lighten-5">
        <v-text-field
          v-model="edpesquisa"
          hide-details
          single-line
          label="Pesquisa"
        ></v-text-field>
        <v-btn :loading="isloading" icon @click="consultarapi()">
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </v-toolbar>
    </div>
    <div class="pa-2">
      <v-data-table
        v-if="bolMostraGrid"
        :headers="headers"
        :items="dadosConsulta"
        sort-by="nome"
        class="elevation-2"
        disable-sort
        disable-pagination
        hide-default-footer
        dense
        loading-text="Carregando..."
        no-data-text="Nenhum registro encontrado"
      >
      </v-data-table>
    </div>
    <!-- div da pesquisa -->
    <v-snackbar v-model="snackbar" timeout="4000">
      {{ snackbartext }}
    </v-snackbar>
  </v-container>
</template>

<script>
import api from "../services/api.js";

export default {
  name: "Produtos",

  data() {
    return {
      snackbar: false,
      snackbartext: "",
      isloading: false,
      bolMostraGrid: false,
      dadosConsulta: [],
      edpesquisa: "",
      headers: [
        {
          text: "Produto",
          align: "start",
          value: "NOME_PRO",
        },
        { text: "Un", value: "UN_PRO" },
        { text: "Valor custo", value: "VALOR_CUSTO" },
        { text: "Valor venda", value: "VALOR_VENDA" },
        { text: "CMV (%)", value: "CALCULO_CMV" },
        { text: "Saldo", value: "SALDO" },
      ],
    };
  },
  mounted() {
    this.$store.state.vtitulojanela = "Produtos";
  },
  methods: {
    consultarapi() {
      if (this.edpesquisa.length >= 3) {
        this.isloading = true;
        let sql =
          "select p.n_pro, p.nome_pro, p.un_pro, cast(a.valor_venda as float) valor_venda, cast(a.valor_custo as float) valor_custo, cast(a.calculo_cmv as float) calculo_cmv, " +
          "(select s.psaldo from sp_saldounitario_marca(" +
          this.$store.state.vempresa +
          ",0, a.n_pro_marca) s) SALDO " +
          "from produtos p, produtos_marcas a " +
          "where p.n_pro = a.n_pro " +
          // "and p.n_emp = " +
          // this.$store.state.vempresa +
          // " " +
          "and p.tipo_pro = 'VENDA' " +
          "and p.tipo_material is null " +
          "and p.status_pro = 'ATIVO' " +
          "and p.nome_pro <> '' " +
          "and p.nome_pro like '%" +
          this.edpesquisa +
          "%' " +
          "order by p.nome_pro ";
        sql = encodeURIComponent(sql);
        this.dadosConsulta = [];
        api.get("/v1/consulta?sql=" + sql).then((response) => {
          this.dadosConsulta = response.data;
          this.bolMostraGrid = true;
          this.isloading = false;
        });
      }
    },
  },
};
</script>