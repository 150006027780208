<template>
  <v-container fill-height class="justify-center">
    <v-card flat tile>
      <v-img
        max-height="150"
        max-width="280"
        src="../../src/assets/logo-light.png"
      ></v-img>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "Principal",

  data() {
    return {};
  },
  mounted() {
    this.$store.state.vtitulojanela = this.$store.state.vempresanome;
  },
};
</script>

