<template>
  <v-container fluid class="d-flex flex-column">
    <div class="pb-2 text-center text-h6">
      <v-card max-width="500px" class="blue-grey lighten-5">
        <v-col>
          <v-text-field
            v-model="dtData1"
            hide-details
            single-line
            type="date"
            required
            prefix="Data: "
          ></v-text-field>
        </v-col>
        <v-col v-if="bolLocalCarregamento">
          <v-select
            v-model="intN_CAR"
            :items="qlocalcarregamento"
            item-text="NOME_CARREGAMENTO"
            item-value="N_CAR"
            label="Local de carregamento"
            required
            clearable
          ></v-select>
        </v-col>
        <v-col>
          <v-row class="ma-1" justify="space-around">
            <v-btn color="primary" :loading="isloading" @click="consultarapi()">
              Pesquisar
            </v-btn>
          </v-row>
        </v-col>
      </v-card>
    </div>
    <!-- <div v-if="mostradados === true" class="pb-2"> -->

    <v-card
      v-if="bolPesquisa"
      class="pa-2 text-center"
      elevation="0"
      color="primary"
      max-width="500px"
    >
      <v-card dark color="blue darken-4" class="text-center" elevation="0">
        <div>Concreto</div>
      </v-card>
      <v-row>
        <v-col>
          <v-card
            dark
            color="primary"
            class="mt-2 pa-2 text-center"
            elevation="0"
          >
            <div>Programado</div>
            <div>{{ dadosResumo.CONCRETO_PROG }}</div>
          </v-card>
        </v-col>
        <v-col>
          <v-card
            dark
            color="primary"
            class="mt-2 pa-2 text-center"
            elevation="0"
          >
            <div>Enviado</div>
            <div>{{ dadosResumo.CONCRETO_ENVIADO }}</div>
          </v-card>
        </v-col>
        <v-col>
          <v-card
            dark
            color="primary"
            class="mt-2 pa-2 text-center"
            elevation="0"
          >
            <div>Saldo</div>
            <div>{{ dadosResumo.CONCRETO_SALDO }}</div>
          </v-card>
        </v-col>
      </v-row>
    </v-card>

    <v-card
      v-if="bolPesquisa"
      class="pa-2 mt-2 text-center"
      color="primary"
      elevation="0"
      max-width="500px"
    >
      <v-card dark color="blue darken-4" class="text-center" elevation="0">
        <div>Argamassa</div>
      </v-card>
      <v-row>
        <v-col>
          <v-card
            dark
            color="primary"
            class="mt-2 pa-2 text-center"
            elevation="0"
          >
            <div>Programado</div>
            <div>{{ dadosResumo.ARGAMASSA_PROG }}</div>
          </v-card>
        </v-col>
        <v-col>
          <v-card
            dark
            color="primary"
            class="mt-2 pa-2 text-center"
            elevation="0"
          >
            <div>Enviado</div>
            <div>{{ dadosResumo.ARGAMASSA_ENVIADO }}</div>
          </v-card>
        </v-col>
        <v-col>
          <v-card
            dark
            color="primary"
            class="mt-2 pa-2 text-center"
            elevation="0"
          >
            <div>Saldo</div>
            <div>{{ dadosResumo.ARGAMASSA_SALDO }}</div>
          </v-card>
        </v-col>
      </v-row>
    </v-card>

    <v-card
      v-if="bolPesquisa"
      class="pa-2 mt-2 mb-4 text-center"
      color="primary"
      elevation="0"
      max-width="500px"
    >
      <v-card dark color="blue darken-4" class="text-center">
        <div>Bomba</div>
      </v-card>
      <v-row>
        <v-col>
          <v-card
            dark
            color="primary"
            class="mt-2 pa-2 text-center"
            elevation="0"
          >
            <div>Programado</div>
            <div>{{ dadosResumo.BOMBA_PROG }}</div>
          </v-card>
        </v-col>
        <v-col>
          <v-card
            dark
            color="primary"
            class="mt-2 pa-2 text-center"
            elevation="0"
          >
            <div>Enviado</div>
            <div>{{ dadosResumo.BOMBA_ENVIADO }}</div>
          </v-card>
        </v-col>
        <v-col>
          <v-card
            dark
            color="primary"
            class="mt-2 pa-2 text-center"
            elevation="0"
          >
            <div>Saldo</div>
            <div>{{ dadosResumo.BOMBA_SALDO }}</div>
          </v-card>
        </v-col>
      </v-row>
    </v-card>

    <v-card
      dark
      color="blue"
      class="mt-1 mb-1 pa-2 text-caption"
      elevation="0"
      v-for="dadoConsulta in dadosConsulta"
      :key="dadoConsulta.N_PROG"
      max-width="500px"
    >
      <!-- <v-card-text> -->
      <v-card color="blue darken-4">
        <v-chip class="ml-1" color="blue darken-4" text-color="white">
          {{ dadoConsulta.ORDEM_PROG }}
        </v-chip>
        <v-chip class="ml-1" color="blue darken-4" text-color="white">
          {{ dadoConsulta.HORARIO_PROG }}
        </v-chip>
        <v-chip class="ml-1" color="blue darken-4" text-color="white">
          Contrato: {{ dadoConsulta.NUMERO_CONTRATO }}
        </v-chip>
        <v-chip class="ml-1" color="blue darken-4" text-color="white">
          Bomba: {{ dadoConsulta.BOMBA_PROG }}
        </v-chip>
      </v-card>
      <v-card class="pa-2" color="primary" elevation="0">
        <div>Cliente: {{ dadoConsulta.RAZAO_PES }}</div>
        <div>Produto: {{ dadoConsulta.NOME_PRO }}</div>
      </v-card>
      <v-card color="primary" elevation="0">
        <v-chip class="ml-1" color="primary" text-color="white">
          Prog: {{ dadoConsulta.QTDE_PROG }}
        </v-chip>
        <v-chip class="ml-1" color="primary" text-color="white">
          Env: {{ dadoConsulta.QTDE_ENVIADA }}
        </v-chip>
        <v-chip
          class="ml-1"
          small
          :color="dadoConsulta.SALDO_PROG === '0,00' ? 'primary' : 'error'"
          text-color="white"
          label
        >
          Saldo: {{ dadoConsulta.SALDO_PROG }}
        </v-chip>
        <v-btn
          v-if="mostrabotaoeditar"
          color="success"
          class="ma-2"
          dark
          small
          :loading="isloading"
          @click="btnalterarclick(dadoConsulta)"
        >
          Editar
        </v-btn>
      </v-card>
      <!-- <v-row class="ma-1" justify="space-around"> -->
      <!-- </v-row> -->
    </v-card>

    <v-footer
      v-if="strStatusRegistro === ''"
      app
      color="primary"
      height="55"
      elevation="20"
    >
      <v-row class="justify-end">
        <v-btn
          v-if="this.$store.state.qPerfil.INCLUIR === 'S'"
          class="ma-2"
          fab
          dark
          small
          color="blue"
          @click.stop="dialogContratos = true"
        >
          <v-icon dark> mdi-plus </v-icon>
        </v-btn>
      </v-row>
    </v-footer>

    <v-dialog
      fullscreen
      transition="dialog-bottom-transition"
      v-model="dialogContratos"
      max-width="290"
    >
      <v-card>
        <v-toolbar dark color="green darken-1">
          <v-btn icon @click.stop="dialogContratos = false">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-toolbar-title>Selecione um contrato</v-toolbar-title>
        </v-toolbar>

        <v-toolbar>
          <v-text-field
            v-model="edpesquisa"
            hide-details
            single-line
            label="Pesquisa"
          ></v-text-field>
          <v-btn :loading="isloading" icon @click="consultaContratos()">
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </v-toolbar>

        <v-data-table
          v-if="bolMostraGrid"
          :headers="headersContratos"
          :items="dadosContratos"
          sort-by="NOME_PRO"
          class="elevation-2"
          disable-sort
          disable-pagination
          hide-default-footer
          dense
          loading-text="Carregando..."
          no-data-text="Nenhum registro encontrado"
        >
          <template v-slot:[`item.acoes`]="{ item }">
            <v-btn
              class="mr-1 my-1"
              outlined
              color="green darken-1"
              dark
              small
              :loading="isloading"
              @click.stop="btnincluirclick(item)"
            >
              Selecionar
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-dialog>
    <v-dialog
      fullscreen
      transition="dialog-bottom-transition"
      v-model="dialogProgramacao"
      max-width="290"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>PROGRAMAÇÃO DE ENTREGA</v-toolbar-title>
        </v-toolbar>
        <div>
          <v-card class="pa-2">
            <v-form ref="form" lazy-validation>
              <v-col>
                <v-text-field
                  v-model="tpai.NUMERO_CONTRATO"
                  label="Contrato"
                  disabled
                  required
                ></v-text-field>
                <v-text-field
                  v-model="tpai.NOME_CLIENTE"
                  label="Cliente"
                  disabled
                  required
                ></v-text-field>
                <v-text-field
                  v-model="tpai.APLICACAO_PROG"
                  label="Aplicação"
                  disabled
                  required
                ></v-text-field>
              </v-col>
              <v-col v-if="bolLocalCarregamento">
                <v-select
                  v-model="tpai.N_CAR"
                  :items="qlocalcarregamento"
                  item-text="NOME_CARREGAMENTO"
                  item-value="N_CAR"
                  label="Local de carregamento"
                  dense
                  required
                ></v-select>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="tpai.DATA_PROG"
                  hide-details
                  single-line
                  type="date"
                  required
                  label="Data"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="tpai.ORDEM_PROG"
                  label="Ordem"
                  required
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="tpai.HORARIO_PROG"
                  label="Horário"
                  type="time"
                  required
                ></v-text-field>
              </v-col>
              <v-col>
                <v-select
                  v-model="tpai.N_PRO"
                  :items="qtracos"
                  item-text="NOME_PRO"
                  item-value="N_PRO"
                  label="Traço"
                  dense
                  required
                ></v-select>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="tpai.QTDE_PROG"
                  label="Qtde"
                  required
                ></v-text-field>
              </v-col>
              <v-col>
                <v-select
                  v-model="tpai.BOMBA_PROG"
                  :items="itemsBomba"
                  item-text="descricao"
                  item-value="chave"
                  label="Bomba"
                ></v-select>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="tpai.BOMBADESC_PROG"
                  label="Descrição da bomba"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="tpai.QTDEVEICRESERVA_PROG"
                  label="Qtde veículos reservados"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-select
                  v-model="tpai.N_PES_ACOMP"
                  :items="qAcompanhamento"
                  item-text="RAZAO_PES"
                  item-value="N_PES"
                  label="Acompanhamento"
                  dense
                  clearable
                ></v-select>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="tpai.OBS_PROG"
                  label="Observações"
                  required
                ></v-text-field>
              </v-col>
              <v-col></v-col>
              <v-col></v-col>
            </v-form>
          </v-card>
        </div>
        <v-footer v-if="strStatusRegistro !== ''" app color="primary">
          <v-btn color="success" class="mr-4" @click="btnsalvarclick">
            Salvar
          </v-btn>
          <v-btn color="warning" class="mr-4" @click="btncancelarclick">
            Cancelar
          </v-btn>
          <v-bottom-sheet
            v-if="
              strStatusRegistro === 'EDIT' &&
              this.$store.state.qPerfil.EXCLUIR === 'S'
            "
            v-model="sheet"
            persistent
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="error" v-bind="attrs" v-on="on"> Excluir </v-btn>
            </template>
            <v-sheet class="text-center" height="200px">
              <div class="py-3">Deseja realmente excluir este registro?</div>
              <v-card class="d-flex justify-space-around mb-6" flat tile>
                <v-btn color="error" @click="btnexcluirclick(tpai)">
                  Sim
                </v-btn>
                <v-btn color="success" @click="sheet = !sheet"> Não </v-btn>
              </v-card>
            </v-sheet>
          </v-bottom-sheet>
        </v-footer>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" timeout="4000">
      {{ snackbartext }}
    </v-snackbar>
  </v-container>
</template>

<script>
import api from "../services/api.js";

export default {
  name: "Programacao",
  data() {
    return {
      strStatusRegistro: "",
      isloading: false,
      bolMostraGrid: false,
      bolPesquisa: false,
      dialogContratos: false,
      dialogProgramacao: false,
      mostrabotaoeditar: false,
      datahoje: "",
      dtData1: "",
      intN_CAR: "",
      edpesquisa: "",
      sheet: false,
      bolLocalCarregamento: false,
      snackbartext: "",
      snackbar: false,
      itemsBomba: [
        {
          chave: "S",
          descricao: "SIM",
        },
        { chave: "N", descricao: "NÃO" },
      ],
      tpai: {
        N_PROG: "",
        N_USU: "",
        N_EMP: "",
        DATA_PROG: "",
        N_CON: "",
        NUMERO_CONTRATO: "",
        NOME_CLIENTE: "",
        N_PRO: "",
        QTDE_PROG: "",
        BOMBA_PROG: "",
        BOMBADESC_PROG: "",
        OBS_PROG: "",
        HORARIO_PROG: "",
        ORDEM_PROG: "",
        N_CAR: "",
        APLICACAO_PROG: "",
        QTDEVEICRESERVA_PROG: "",
        N_PES_ACOMP: "",
      },
      qlocalcarregamento: [],
      qtracos: [],
      qprogramacao: [],
      dadosConsulta: [],
      dadosResumo: [],
      dadosContratos: [],
      qAcompanhamento: [],
      headers: [
        {
          text: "Ordem",
          align: "start",
          value: "ORDEM_PROG",
        },
        { text: "Hora", value: "HORARIO_PROG" },
        { text: "Contrato", value: "NUMERO_CONTRATO" },
        { text: "Traço", value: "NOME_PRO" },
        { text: "Qtde", value: "QTDE_PROG" },
        { text: "Enviado", value: "QTDE_ENVIADA" },
        { text: "Saldo", value: "SALDO_PROG" },
        { text: "Bomba", value: "BOMBA_PROG" },
        { text: "Cliente", value: "RAZAO_PES" },
      ],
      headersContratos: [
        {
          text: "Contrato",
          align: "start",
          value: "NUMERO_CONTRATO",
        },
        { text: "Data", value: "DATA_CONTRATO" },
        { text: "Validade", value: "VALIDADE_CONTRATO" },
        { text: "Razão", value: "RAZAO_PES" },
        { text: "Fantasia", value: "NOME_PES" },
        { text: "CPF/CNPJ", value: "CPFCNPJ_PES" },
        { text: "Obra", value: "ENDCOMPLETO_OBRA" },
        { text: "Cidade/UF", value: "CIDADE_OBRA" },
        { text: "Status", value: "STATUS_CONTRATO" },
        { text: "", value: "acoes", sortable: false },
      ],
    };
  },
  mounted() {
    this.$store.state.vtitulojanela = "PROGRAMAÇÃO DE ENTREGA";
    this.strStatusRegistro = "";
    this.getNow();
    this.dtData1 = this.datahoje;
    this.verificalocalcarregamento();
    this.carregafuncionarios();
    if (this.$store.state.qPerfil.ALTERAR === "S") {
      this.mostrabotaoeditar = true;
    } else {
      this.mostrabotaoeditar = false;
    }
  },
  methods: {
    btnalterarclick(item) {
      this.isloading = true;
      this.sqlConsulta =
        "select p.*, c.numero_contrato, a.razao_pes " +
        "from programacao p, contratos c, adm_pessoa a " +
        "where p.n_con = c.n_con " +
        "and c.n_pes_cliente = a.n_pes " +
        "and p.n_prog = " +
        item.N_PROG;
      this.sqlConsulta = encodeURIComponent(this.sqlConsulta);
      this.qprogramacao = [];
      api.get("/v1/consulta?sql=" + this.sqlConsulta).then((response) => {
        this.qprogramacao = response.data;
        this.isloading = false;

        this.edpesquisa = "";
        this.tpai.N_PROG = this.qprogramacao[0].N_PROG;
        this.tpai.N_USU = this.qprogramacao[0].N_USU;
        this.tpai.N_EMP = this.qprogramacao[0].N_EMP;
        this.tpai.DATA_PROG = this.formatDateyyyymmdd(
          this.qprogramacao[0].DATA_PROG
        );
        this.tpai.N_CON = this.qprogramacao[0].N_CON;
        this.tpai.NUMERO_CONTRATO = this.qprogramacao[0].NUMERO_CONTRATO;
        this.tpai.NOME_CLIENTE = this.qprogramacao[0].RAZAO_PES;
        this.tpai.N_PRO = this.qprogramacao[0].N_PRO;
        this.tpai.QTDE_PROG = this.qprogramacao[0].QTDE_PROG;
        this.tpai.BOMBA_PROG = this.qprogramacao[0].BOMBA_PROG;
        this.tpai.BOMBADESC_PROG = this.qprogramacao[0].BOMBADESC_PROG;
        this.tpai.OBS_PROG = this.qprogramacao[0].OBS_PROG;
        this.tpai.HORARIO_PROG = this.qprogramacao[0].HORARIO_PROG;
        this.tpai.ORDEM_PROG = this.qprogramacao[0].ORDEM_PROG;
        this.tpai.N_CAR = this.qprogramacao[0].N_CAR;
        this.tpai.APLICACAO_PROG = this.qprogramacao[0].APLICACAO_PROG;
        this.strStatusRegistro = "EDIT";
        this.dialogProgramacao = true;
        this.dialogContratos = false;
        this.carregatracos(this.tpai.N_CON);
        this.dadosContratos = [];
      });
    },
    replacer(key, value) {
      // Filtering out properties
      if (value === null) {
        return "";
      }
      return value;
    },
    btnsalvarclick() {
      api
        .post(
          "/v1/programacao",
          JSON.stringify(this.tpai, this.replacer).toUpperCase()
        )
        .then((response) => {
          if (response.data.RESULTADO === "OK") {
            this.dialogProgramacao = false;
            this.strStatusRegistro = "";
            this.consultarapi();
          } else {
            this.snackbartext = response.data.ERRO;
            this.snackbar = true;
          }
        });
    },
    btncancelarclick() {
      this.isloading = false;
      this.dialogProgramacao = false;
      this.strStatusRegistro = "";
    },
    btnincluirclick(item) {
      this.edpesquisa = "";
      this.tpai.N_PROG = "0"; //indica inclusão
      this.tpai.N_USU = this.$store.state.codusuario;
      this.tpai.N_EMP = this.$store.state.vempresa;
      this.tpai.DATA_PROG = this.datahoje;
      this.tpai.N_CON = item.N_CON;
      this.tpai.NUMERO_CONTRATO = item.NUMERO_CONTRATO;
      this.tpai.NOME_CLIENTE = item.RAZAO_PES;
      this.tpai.N_PRO = "";
      this.tpai.QTDE_PROG = "";
      this.tpai.BOMBA_PROG = "";
      this.tpai.BOMBADESC_PROG = "";
      this.tpai.OBS_PROG = "";
      this.tpai.HORARIO_PROG = "";
      this.tpai.ORDEM_PROG = "";
      this.tpai.N_CAR = "";
      this.tpai.APLICACAO_PROG = item.APLICACAO_CONCRETO;
      this.strStatusRegistro = "INSERT";
      this.dialogProgramacao = true;
      this.dialogContratos = false;
      this.carregatracos(item.N_CON);
      this.dadosContratos = [];
    },
    btnexcluirclick() {
      api
        .delete("/v1/programacao", {
          data: JSON.stringify(this.tpai, this.replacer).toUpperCase(),
        })
        .then((response) => {
          if (response.data.RESULTADO === "OK") {
            this.dialogProgramacao = false;
            this.strStatusRegistro = "";
            this.consultarapi();
            this.sheet = false;
          } else {
            this.dialogProgramacao = false;
            this.strStatusRegistro = "";
            this.sheet = false;
            this.snackbartext = "Esta programação já gerou O.S.";
            this.snackbar = true;
          }
        });
    },
    getNow: function () {
      const today = new Date().toISOString().substr(0, 10);
      this.datahoje = today;
    },
    formatDateFirebird(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    formatDateBR(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    formatDateyyyymmdd(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      return `${year}-${month}-${day}`;
    },
    verificalocalcarregamento() {
      this.isloading = true;
      this.sqlConsulta =
        "select e.concreteira_carregamento_os from empresa e where e.n_emp = " +
        this.$store.state.vempresa;
      this.sqlConsulta = encodeURIComponent(this.sqlConsulta);
      this.dadosConsulta = [];
      api.get("/v1/consulta?sql=" + this.sqlConsulta).then((response) => {
        if (response.data[0].CONCRETEIRA_CARREGAMENTO_OS === "S") {
          let sql1 =
            "select * from carregamento" +
            " where n_emp = " +
            this.$store.state.vempresa +
            " order by nome_carregamento";
          sql1 = encodeURIComponent(sql1);
          this.qlocalcarregamento = [];
          api.get("/v1/consulta?sql=" + sql1).then((response) => {
            this.qlocalcarregamento = response.data;
          });
          this.bolLocalCarregamento = true;
        } else {
          this.bolLocalCarregamento = false;
        }
        this.isloading = false;
      });
    },
    consultarapi() {
      if (this.dtData1 != "") {
        this.isloading = true;
        this.dadosResumo = [];
        api
          .get(
            "/v1/programacao?data=" +
              this.formatDateBR(this.dtData1) +
              "&empresa=" +
              this.$store.state.vempresa +
              "&localcar=" +
              this.intN_CAR
          )
          .then((response) => {
            this.dadosResumo = response.data;
            this.bolPesquisa = true;
            this.isloading = false;
          });

        this.isloading = true;
        this.sqlConsulta =
          "select p.n_prog, p.ordem_prog, p.horario_prog, p.qtde_prog, p.bomba_prog, t.nome_pro, t.nomeimpressao_pro, u.login, " +
          "c.numero_contrato, e.razao_pes, e.tel1_pes, e.celular_pes, c.n_con, " +
          "COALESCE((select sum(o.qtde) total " +
          "from ordem_servico o " +
          "where o.n_con = p.n_con " +
          "and o.n_pro = p.n_pro " +
          "and o.data_os = p.data_prog " +
          "and o.status = 'NORMAL' " +
          "),0) qtde_enviada, " +
          "(COALESCE(QTDE_PROG,0) - COALESCE((select sum(o.qtde) total " +
          "from ordem_servico o " +
          "where o.n_con = p.n_con " +
          "and o.n_pro = p.n_pro " +
          "and o.data_os = p.data_prog " +
          "and o.status = 'NORMAL' " +
          "),0)) saldo_prog " +
          "from programacao p, contratos c, produtos t, usuario u, adm_pessoa e " +
          "where p.n_emp = " +
          this.$store.state.vempresa +
          " " +
          "and p.data_prog = '" +
          this.formatDateFirebird(this.dtData1) +
          "' " +
          "and p.n_con = c.n_con " +
          "and p.n_pro = t.n_pro " +
          "and p.n_usu = u.n_usu " +
          "and c.n_pes_cliente = e.n_pes ";
        if (this.intN_CAR > 0) {
          this.sqlConsulta =
            this.sqlConsulta + "and p.n_car = " + this.intN_CAR;
        }
        this.sqlConsulta =
          this.sqlConsulta + " order by p.ordem_prog, p.horario_prog";

        this.sqlConsulta = encodeURIComponent(this.sqlConsulta);
        this.dadosConsulta = [];
        api.get("/v1/consulta?sql=" + this.sqlConsulta).then((response) => {
          this.dadosConsulta = response.data;
          this.isloading = false;
        });
      }
    },
    consultaContratos() {
      if (this.edpesquisa.length >= 3) {
        this.isloading = true;
        this.sqlConsulta =
          "select c.numero_contrato, c.end_obra, c.numero_obra, c.cidade_obra||'/'||c.uf_obra cidade_obra, c.uf_obra, c.validade_contrato, c.n_pes_cliente, u.nome_completo, " +
          "c.data_contrato, p.razao_pes, p.nome_pes, p.cpfcnpj_pes, c.n_con, c.limite_faturamento, c.prazo_pagamento, c.endcompleto_obra, c.data_cad, c.hora_cad, c.status_contrato, " +
          "iif(c.ASSINATURACLIB64_CON is null,0,1) TEM_ASSINATURA, c.aplicacao_concreto " +
          "from adm_pessoa p, contratos c " +
          "left outer join usuario u on u.n_usu = c.n_usu " +
          "where p.n_pes = c.n_pes_cliente " +
          "and ((p.razao_pes like '%" +
          this.edpesquisa +
          "%') or (p.nome_pes like '%" +
          this.edpesquisa +
          "%') or (c.numero_contrato like '%" +
          this.edpesquisa +
          "%') or (p.cpfcnpj_pes like '%" +
          this.edpesquisa +
          "%')) " +
          "order by c.data_contrato desc, c.n_con desc";
        this.sqlConsulta = encodeURIComponent(this.sqlConsulta);
        this.dadosContratos = [];
        api.get("/v1/consulta?sql=" + this.sqlConsulta).then((response) => {
          this.dadosContratos = response.data;
          this.bolMostraGrid = true;
          this.isloading = false;
        });
      }
    },
    carregatracos(id) {
      this.isloading = true;
      this.sqlConsulta =
        "select p.n_pro, p.nome_pro " +
        "from itens_contrato i, produtos p " +
        "where i.n_pro = p.n_pro " +
        "and i.status = 'ATIVO' " +
        "and i.n_con = " +
        id +
        " " +
        "and p.n_emp = " +
        this.$store.state.vempresa +
        " " +
        "order by p.nome_pro";
      this.sqlConsulta = encodeURIComponent(this.sqlConsulta);
      this.qtracos = [];
      api.get("/v1/consulta?sql=" + this.sqlConsulta).then((response) => {
        this.qtracos = response.data;
        this.isloading = false;
      });
    },
    carregafuncionarios() {
      this.isloading = true;
      this.sqlConsulta =
        "select p.n_pes, p.razao_pes, p.email_pes " +
        "from adm_pessoa p " +
        "where p.tipopes_pes = 'FUNCIONARIO' " +
        "and p.status_pes = 'ATIVO' " +
        "order by p.razao_pes";
      this.sqlConsulta = encodeURIComponent(this.sqlConsulta);
      this.qAcompanhamento = [];
      api.get("/v1/consulta?sql=" + this.sqlConsulta).then((response) => {
        this.qAcompanhamento = response.data;
        this.isloading = false;
      });
    },
  },
};
</script>